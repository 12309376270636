import React, { useState, useEffect } from 'react';
import './RelatedProducts.css';
import { Item } from '../Item/Item';

export const RelatedProducts = ({ category, type }) => {
  const [relatedProducts, setRelatedProducts] = useState([]);

  useEffect(() => {
    const fetchRelatedProducts = async () => {
      try {
        const response = await fetch(`https://api.topcare.fashion/api/products/related-products?category=${category}&type=${type}`);
        const data = await response.json();
        setRelatedProducts(data);
      } catch (error) {
        console.error('Error fetching related products:', error);
      }
    };

    fetchRelatedProducts();
  }, [category, type]);

  return (
    <div className='relatedproducts'>
      <h1>Related Products</h1>
      <hr />
      <div className="relatedproducts-item">
        {relatedProducts.map((item, i) => (
          <Item key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} amountSold={item.amountSold}/>
        ))}
      </div>
    </div>
  );
};
