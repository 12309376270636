// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.seller-profile {
    width: 355px; /* Adjust width to make the box smaller */
    padding: 15px;
    border: 1px solid #d0d0d0;
    background-color: #faefe9;
    margin: 0px 170px;
    text-align: center; /* Center the text */
    margin-top: 15px;
  }
  
  .seller-profile p {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .chat-button {
    background-color: #8d4585 !important; /* Original color */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .chat-button:hover {
    background-color: #242566 !important; /* Lighter shade on hover */
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/SellerProfile/SellerProfile.css"],"names":[],"mappings":"AAAA;IACI,YAAY,EAAE,yCAAyC;IACvD,aAAa;IACb,yBAAyB;IACzB,yBAAyB;IACzB,iBAAiB;IACjB,kBAAkB,EAAE,oBAAoB;IACxC,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,oCAAoC,EAAE,mBAAmB;IACzD,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,oCAAoC,EAAE,2BAA2B;EACnE","sourcesContent":[".seller-profile {\n    width: 355px; /* Adjust width to make the box smaller */\n    padding: 15px;\n    border: 1px solid #d0d0d0;\n    background-color: #faefe9;\n    margin: 0px 170px;\n    text-align: center; /* Center the text */\n    margin-top: 15px;\n  }\n  \n  .seller-profile p {\n    font-size: 16px;\n    font-weight: 600;\n    margin-bottom: 10px;\n  }\n  \n  .chat-button {\n    background-color: #8d4585 !important; /* Original color */\n    color: white;\n    border: none;\n    border-radius: 4px;\n    padding: 10px 20px;\n    cursor: pointer;\n    font-size: 16px;\n  }\n  \n  .chat-button:hover {\n    background-color: #242566 !important; /* Lighter shade on hover */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
