import React from 'react'
import './Seller.css'
import Sidebar from '../../Components/Sidebar/Sidebar'
import { Routes, Route } from 'react-router-dom'
import AddProduct from '../../Components/AddProduct/AddProduct'
import ListProduct from '../../Components/ListProduct/ListProduct'
import Orders from '../../Components/Orders/Orders'
import RevenueData from '../../Components/RevenueData/RevenueData'
import ReviewManagement from '../../Components/ReviewManagement/ReviewManagement'
import ChatManagement from '../../Components/ChatManagement/ChatManagement'
import ChatWithBuyer from '../../Components/ChatWithBuyer/ChatWithBuyer'

const Seller = () => {
  return (
    <div className='seller'>
        <Sidebar/>
        
        <Routes>
          <Route path='addproduct' element={<AddProduct/>} />
          <Route path='listproduct' element={<ListProduct/>} />
          <Route path='orders' element={<Orders/>} />
          <Route path='revenuedata' element={<RevenueData/>} />
          <Route path='reviewmanagement' element={<ReviewManagement/>} />
          <Route path='chatmanagement' element={<ChatManagement/>} />
          <Route path='/chatwithbuyer' element={<ChatWithBuyer/>} />
        </Routes>
    </div>
  )
}

export default Seller