import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Hero.css'; 
import banner_1 from '../Assets/banners/1.jpg';
import banner_2 from '../Assets/banners/2.jpg';
import banner_3 from '../Assets/banners/3.jpg';
import banner_4 from '../Assets/banners/4.jpg';
import banner_5 from '../Assets/banners/5.jpg';

const Hero = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(true);
  const totalSlides = 5; // Total number of original banners

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1));
    }, 3000); // 3 seconds per slide

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  useEffect(() => {
    if (currentSlide === totalSlides) {
      // Briefly disable the animation, reset to slide 0, and restore animation
      setTimeout(() => {
        setIsAnimating(false);
        setCurrentSlide(0);
      }, 1000); // After 1 second (matches the transition duration)
      
      // Re-enable animation after resetting
      setTimeout(() => {
        setIsAnimating(true);
      }, 1100); // Re-enable after a small delay to allow instant jump
    }
  }, [currentSlide, totalSlides]);

  return (
    <div className="carousel-container">
      <div
        className="carousel"
        style={{
          transform: `translateX(-${currentSlide * 1560}px)`,
          transition: isAnimating ? 'transform 1s ease-in-out' : 'none', // Disable animation during reset
        }}
      >
        <div className="carousel-item">
          <Link to='/men'><img src={banner_1} alt="Banner 1" /></Link>
        </div>
        <div className="carousel-item">
          <Link to='/women'><img src={banner_2} alt="Banner 2" /></Link>
        </div>
        <div className="carousel-item">
          <Link to='/men'><img src={banner_3} alt="Banner 3" /></Link>
        </div>
        <div className="carousel-item">
          <Link to='/men'><img src={banner_4} alt="Banner 4" /></Link>       
        </div>
        <div className="carousel-item">
          <Link to='/women'><img src={banner_5} alt="Banner 5" /></Link>
        </div>
        {/* Clone of the first image for seamless looping */}
        <div className="carousel-item">
          <img src={banner_1} alt="Banner 1 Clone" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
