import React, { useState, useEffect } from 'react';
import './CSS/EditProfile.css';

const EditProfile = () => {
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        shippingAddress: '',
        gender: '',
        birthday: '',
    });

    useEffect(() => {
        // Fetch current user data to prefill the form
        const fetchUserData = async () => {
            await fetch('https://api.topcare.fashion/api/user/profile', {
                headers: {
                    'auth-token': localStorage.getItem('auth-token'),
                }
            })
            .then((res) => res.json())
            .then((data) => setUserData({
                name: data.name,
                email: data.email,
                shippingAddress: data.shippingAddress,
                gender: data.gender,
                birthday: new Date(data.birthday).toISOString().split('T')[0], // Format to YYYY-MM-DD
            }));
        }

        fetchUserData();
    }, []);

    const handleChange = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Update user data via API
        await fetch('https://api.topcare.fashion/api/user/profile/update', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'auth-token': localStorage.getItem('auth-token'),
            },
            body: JSON.stringify(userData)
        })
        .then((res) => res.json())
        .then((data) => {
            if (data.success) {
                alert('Profile updated successfully');
                window.location.replace('/profile');
            } else {
                alert('Failed to update profile');
            }
        });
    }
    
    return (
        <div className="edit-profile">
            <h1>Edit Profile</h1>
            <form onSubmit={handleSubmit} className="edit-profile-form">
                <div className="form-group">
                    <label>Name</label>
                    <input
                        type="text"
                        name="name"
                        value={userData.name}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Email</label>
                    <input
                        type="email"
                        name="email"
                        value={userData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                
                <div className="form-group">
                    <label>Gender</label>
                    <select
                        name="gender"
                        value={userData.gender}
                        onChange={handleChange}
                        required
                    >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                    </select>
                </div>

                <div className="form-group">
                    <label>Birthday</label>
                    <input
                        type="date"
                        name="birthday"
                        value={userData.birthday}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Shipping Address</label>
                    <input
                        type="text"
                        name="shippingAddress"
                        value={userData.shippingAddress}
                        onChange={handleChange}
                    />
                </div>

                <button type="submit" className="save-btn">Save</button>
            </form>
        </div>
    );
}

export default EditProfile;
