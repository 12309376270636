import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Barcode from 'react-barcode'; // Assuming you already have this for the barcode
import './CSS/ReturnProduct.css'; // Assuming you have the CSS

const ReturnProduct = () => {
    const { orderId } = useParams(); // Get the orderId from the route params
    const navigate = useNavigate();  // Hook for navigation

    // Function to handle return process
    const handleReturn = async () => {
        try {
            const response = await fetch(`https://api.topcare.fashion/api/orders/${orderId}/return`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': localStorage.getItem('auth-token'), // Assuming auth token is stored in localStorage
                },
            });

            const data = await response.json();

            if (response.ok) {
                // Show success message
                alert("Product Return Requested");

                // Navigate back to the orders page
                navigate('/orders');
            } else {
                // Handle error cases
                alert(`Error: ${data.error}`);
            }
        } catch (error) {
            console.error('Error initiating return:', error);
            alert("An error occurred while processing the return.");
        }
    };

    return (
        <div className="return-product-container">
            <h2>Return Product</h2>

            {/* Display Order ID as Barcode */}
            <div className="barcode-container">
                <h4>Tracking Number:</h4>
                {orderId ? <Barcode value={orderId} /> : <p>No Order ID found</p>}
                <h4>Print the return label and attach it to your package.</h4>
            </div>

            {/* Return Policy */}
            <div className="return-policy">
                <h3>Return Policy</h3>
                <ul>
                    <li>Items must be in original condition, unused, and with all tags attached.</li>
                    <li>Returns must be made within 30 days of delivery.</li>
                    <li>Refunds will be processed to the original payment method within 7-10 business days of receiving the returned item(s).</li>
                    <li>Please note that shipping costs are non-refundable.</li>
                </ul>
            </div>

            {/* Return Button */}
            <button className="return-button" onClick={handleReturn}>
                Proceed with Return
            </button>
        </div>
    );
};

export default ReturnProduct;
