import React, { useState } from 'react';
import './AddProduct.css';
import upload_area from '../../assets/upload_area.svg';

const AddProduct = () => {
    const [image, setImage] = useState(null);
    const [, setProcessingResult] = useState('');
    const [loading, setLoading] = useState(false);
    const [detectionInProgress, setDetectionInProgress] = useState(false);
    const [enable3D, setEnable3D] = useState(false);
    const [model3DImage, setModel3DImage] = useState(null);

    const [productDetails, setProductDetails] = useState({
        name: "",
        image: "",
        category: "women",
        type: "",
        new_price: "",
        quantity: "",
        description: "",
        modelType: ""
    });

    const imageHandler = async (e) => {
        const selectedImage = e.target.files[0];
        setImage(selectedImage);

        if (selectedImage) {
            await detectProductType(selectedImage);
        }
    };

    const model3DImageHandler = (e) => {
        const selectedImage = e.target.files[0];
        setModel3DImage(selectedImage);
    };

    const changeHandler = (e) => {
        setProductDetails({ ...productDetails, [e.target.name]: e.target.value });
    };

    const detectProductType = async (imageFile) => {
        let formData = new FormData();
        formData.append('file', imageFile); // Add the image file
    
        setDetectionInProgress(true);
    
        try {
            // Send the image to the local /predict endpoint in your Node.js server
            const response = await fetch('https://api.topcare.fashion/predict', {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = await response.json();
    
            if (data.error) {
                console.error('API Error:', data.error);
                setProcessingResult('footwear'); // Default fallback
                return;
            }
    
            if (data.category) {
                const detectedCategory = data.category.toLowerCase() === 'unknown' 
                    ? 'footwear' 
                    : data.category.toLowerCase();
    
                setProcessingResult(detectedCategory);
                
                // Update product type in the form
                setProductDetails(prevDetails => ({
                    ...prevDetails,
                    type: detectedCategory
                }));
            } else {
                setProcessingResult('footwear');
                setProductDetails(prevDetails => ({
                    ...prevDetails,
                    type: 'footwear'
                }));
            }
        } catch (error) {
            console.error("Error detecting product type:", error);
            setProcessingResult('footwear');
            setProductDetails(prevDetails => ({
                ...prevDetails,
                type: 'footwear'
            }));
        } finally {
            setDetectionInProgress(false);
            setLoading(false);
        }
    };
    

    const Add_Product = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        try {
            let formData = new FormData();
            
            // Add all required fields to FormData
            formData.append('name', productDetails.name);
            formData.append('category', productDetails.category);
            formData.append('type', productDetails.type);
            formData.append('new_price', productDetails.new_price);
            formData.append('quantity', productDetails.quantity);
            formData.append('description', productDetails.description);
            
            // Add the product image
            if (image) {
                formData.append('productImage', image);
            }
            
            // Add 3D model image if enabled
            if (enable3D && model3DImage) {
                formData.append('model3DImage', model3DImage);
                formData.append('enable3D', 'true');
                formData.append('modelType', productDetails.type);
            }
    
            const response = await fetch('https://api.topcare.fashion/api/products/addproduct', {
                method: 'POST',
                headers: {
                    'auth-token': localStorage.getItem('auth-token'),
                },
                body: formData,
            });
    
            const data = await response.json();
    
            if (data.success) {
                alert("Product Successfully Added");
                // Reset form
                setImage(null);
                setModel3DImage(null);
                setEnable3D(false);
                setProductDetails({
                    name: "",
                    image: "",
                    category: "women",
                    type: "",
                    new_price: "",
                    quantity: "",
                    description: "",
                    modelType: ""
                });
            } else {
                alert(data.message || "Failed to add product");
            }
        } catch (error) {
            console.error("Error adding product:", error);
            alert("Error adding product");
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={Add_Product} className='add-product'>
            {/* Image Upload */}
            <div className="addproduct-itemfield">
                <label htmlFor="file-input">
                    <img 
                        src={image ? URL.createObjectURL(image) : upload_area} 
                        className='addproduct-thumbnail-img' 
                        alt="Upload area" 
                    />
                </label>
                <input 
                    onChange={imageHandler} 
                    type="file" 
                    name='image' 
                    id='file-input' 
                    hidden 
                    accept="image/*"
                />
            </div>

            {/* 3D Model Toggle */}
            <div className="addproduct-3d-toggle">
                <label>
                    <input
                        type="checkbox"
                        checked={enable3D}
                        onChange={(e) => setEnable3D(e.target.checked)}
                    />
                    Enable 3D Model Generation
                </label>
                <p>Please note: Rendering may take 2-3 minutes. For best results, upload an image with a plain background.</p> 
            </div>

            {/* 3D Model Image Upload */}
            {enable3D && (
                <div className="addproduct-itemfield">
                    <p>Upload Image for 3D Model</p>
                    <input
                        type="file"
                        onChange={model3DImageHandler}
                        accept="image/*"
                        className="addproduct-3d-input"
                    />
                </div>
            )}

            {/* Rest of your form fields */}
            <div className="addproduct-category-type">
                <div className="addproduct-itemfield small-input">
                    <p>Category</p>
                    <select 
                        name="category" 
                        value={productDetails.category}
                        onChange={changeHandler}
                        className="add-product-selector"
                    >
                        <option value="women">Women</option>
                        <option value="men">Men</option>
                    </select>
                </div>

                <div className="addproduct-itemfield small-input">
                    <p>Type</p>
                    <select
                        name="type"
                        value={productDetails.type}
                        onChange={changeHandler}
                        className="add-product-selector"
                    >
                        <option value="" disabled>
                            {detectionInProgress ? "Loading..." : "Select Type"}
                        </option>
                        <option value="top">Top</option>
                        <option value="bottom">Bottom</option>
                        <option value="footwear">Footwear</option>
                        <option value="accessory">Accessory</option>
                    </select>
                </div>
            </div>

            <div className="addproduct-itemfield">
                <p>Title</p>
                <input
                    type="text"
                    name="name"
                    value={productDetails.name}
                    onChange={changeHandler}
                    placeholder="Product name"
                    required
                />
            </div>

            <div className="addproduct-itemfield">
                <p>New Price</p>
                <input
                    type="number"
                    name="new_price"
                    value={productDetails.new_price}
                    onChange={changeHandler}
                    placeholder="Price"
                    required
                />
            </div>

            <div className="addproduct-itemfield">
                <p>Quantity</p>
                <input
                    type="number"
                    name="quantity"
                    value={productDetails.quantity}
                    onChange={changeHandler}
                    placeholder="Quantity"
                    required
                />
            </div>

            <div className="addproduct-itemfield">
                <p>Description</p>
                <textarea
                    name="description"
                    value={productDetails.description}
                    onChange={changeHandler}
                    placeholder="Description"
                    required
                />
            </div>

            <button type="submit" disabled={loading || detectionInProgress} className='addproduct-btn'>
                {loading ? "Adding Product..." : "Add Product"}
            </button>
        </form>
    );
};

export default AddProduct;
