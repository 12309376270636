import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import io from 'socket.io-client';
import './ChatWithBuyer.css'

const socket = io('https://api.topcare.fashion'); // Connect to your Socket.IO server

const ChatWithBuyer = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sellerID = params.get('sellerID'); 
  const [sellerName, setSellerName] = useState('');
  const [error, setError] = useState(null);

  const [conversation, setConversation] = useState(null);  // For storing conversation data
  const [newMessage, setNewMessage] = useState('');  // For storing the new message input
  const [messages, setMessages] = useState([]);  // List of chat messages
  const [loading, setLoading] = useState(true);

  // Fetch conversation with the seller
  useEffect(() => {
    const fetchSellerName = async () => {
        try {
          const response = await fetch(`https://api.topcare.fashion/api/user/${sellerID}`);
          
          if (!response.ok) {
            throw new Error('Failed to fetch seller information');
          }
  
          const data = await response.json();
          setSellerName(data.name); // Update based on the field returned by the API
        
        } catch (err) {
          setError(err.message);
        }
      };
  
      fetchSellerName();

    const fetchConversation = async () => {
      try {
        const response = await fetch(`https://api.topcare.fashion/api/chat/${sellerID}`, {
          headers: {
            'auth-token': localStorage.getItem('auth-token'),  // Pass the auth token
          },
        });

        if (response.ok) {
          const data = await response.json();
          setConversation(data);
          setMessages(data.messages);  // Load the existing messages
        } else {
          console.error('Failed to fetch conversation');
        }
      } catch (err) {
        console.error('Error fetching conversation:', err);
      } finally {
        setLoading(false);
      }
    };

    if (sellerID) {
      fetchConversation();
    }

    // Listen for incoming messages via Socket.IO
     socket.on('receiveMessage', (messageData) => {
      setMessages((prevMessages) => [...prevMessages, messageData]); // Append new message to chat
    });

    // Clean up the event listener on unmount
    return () => {
      socket.off('receiveMessage');
    };
  }, [sellerID]);

  // Function to handle sending a new message
  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (!newMessage.trim()) return;  // Avoid sending empty messages

    try {
      const response = await fetch(`https://api.topcare.fashion/api/chat/message/${sellerID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': localStorage.getItem('auth-token'),  // Pass the auth token
        },
        body: JSON.stringify({ message: newMessage }),  // Send the message
      });

      if (response.ok) {
        const data = await response.json();
        socket.emit('sendMessage', data);
        // setMessages((prevMessages) => [...prevMessages, data]);  // Add the new message to the chat
        setNewMessage('');  // Clear the input after sending
      } else {
        console.error('Failed to send message');
      }
    } catch (err) {
      console.error('Error sending message:', err);
    }
  };

  if (loading) return <p>Loading chat...</p>;

  console.log(error);
  console.log(conversation);
  

  return (
    <div className="chat-container">
      <h2>Chat with {sellerName}</h2>

      <div className="chat-box">
        {messages.length > 0 ? (
          messages.map((msg, index) => (
            <div key={index} className={`message ${msg.senderId === sellerID ? 'seller' : 'buyer'}`}>
              <p>{msg.message}</p>
            </div>
          ))
        ) : (
          <p>No messages yet.</p>
        )}
      </div>

      <form className="chat-form" onSubmit={handleSendMessage}>
        <input
          type="text"
          placeholder="Type a message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          required
        />
        <button type="submit" className="send-button">Send</button>
      </form>
    </div>
  );
};

export default ChatWithBuyer;
