import React, { useEffect, useState } from 'react';
import './Popup.css';

const Popup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if the popup has already been closed in the session
    const popupClosed = sessionStorage.getItem('popupClosed');
    
    if (!popupClosed) {
      const timer = setTimeout(() => {
        setIsVisible(true); // Show popup after 2 seconds if not closed
      }, 2000);

      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, []);

  const closePopup = () => {
    setIsVisible(false);
    sessionStorage.setItem('popupClosed', 'true'); // Mark popup as closed in sessionStorage
  };

  return (
    isVisible && (
      <div id="popup" className="popup">
        <div className="popup-content">
          <span className="close-btn" onClick={closePopup}>&times;</span>
          <h2>Welcome to Top Care Fashion!</h2>
          <p>Why buy from us?</p>
          <div className="popup-offers">
            <div className="offer">
              <h3>FREE SHIPPING</h3>
              <p>Gold & Platinum Members</p>
            </div>
            <div className="divider"></div>
            <div className="offer">
              <h3>S$5 OFF</h3>
              <p>Use "save5" During Checkout</p>
            </div>
          </div>
          <p>Only in Singapore</p>
          <button className="shop-now" onClick={closePopup}>Shop Now</button>
        </div>
      </div>
    )
  );
};

export default Popup;
