import React, { useEffect, useState } from 'react';
import './ReviewManagement.css';

const ReviewManagement = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [userNames, setUserNames] = useState({});
  const [loadingReviews, setLoadingReviews] = useState(false);

  // Fetch All products from API
  const fetchInfo = async () => {
    try {
      const response = await fetch('https://api.topcare.fashion/api/products/myproducts', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'auth-token': `${localStorage.getItem('auth-token')}`,
        },
      });

      const data = await response.json();
      setAllProducts(data.products || []);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  // Fetch reviews for a selected product and usernames associated with those reviews
  const fetchReviews = async (productId) => {
    setLoadingReviews(true);
    setReviews([]); // Reset reviews when fetching new ones

    try {
      const response = await fetch(`https://api.topcare.fashion/api/reviews/${productId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch reviews');
      }

      const data = await response.json();
      setReviews(data);

      // Fetch usernames for each review
      const userIds = data.map((review) => review.userID);
      const uniqueUserIds = [...new Set(userIds)];
      
      const userNamePromises = uniqueUserIds.map((userID) =>
        fetch(`https://api.topcare.fashion/api/user/${userID}`)
          .then((response) => response.json())
          .then((userData) => ({ userID, name: userData.name }))
      );

      const userNameData = await Promise.all(userNamePromises);
      const userNamesMap = userNameData.reduce((acc, { userID, name }) => {
        acc[userID] = name;
        return acc;
      }, {});

      setUserNames(userNamesMap);
    } catch (error) {
      console.error('Error fetching reviews:', error);
    } finally {
      setLoadingReviews(false);
    }
  };

  // Handle product selection to view reviews
  const handleProductClick = (product) => {
    setSelectedProduct(product);
    fetchReviews(product.id); // Reset and fetch reviews for the selected product
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return (
    <div className="review-management">
      <h2>Your Products</h2>
      <ul className="product-list">
        {allProducts.map((product) => (
          <li key={product._id} onClick={() => handleProductClick(product)}>
            <img src={product.image} alt={product.name} className="product-image" />
            <p>{product.name}</p>
          </li>
        ))}
      </ul>

      {selectedProduct && (
        <div className="product-reviews">
          <h3>Reviews for {selectedProduct.name}</h3>
          {loadingReviews ? (
            <p>Loading reviews...</p>
          ) : reviews.length === 0 ? (
            <p>No reviews yet for this product.</p>
          ) : (
            <ul className="review-list">
              {reviews.map((review) => (
                <li key={review._id} className="review-item">
                  <p><strong>User: {userNames[review.userID] || 'Loading...'}</strong></p>
                  <p><strong>Rating:</strong> {review.rating} / 5</p>
                  <p><strong>Comment:</strong> {review.comment}</p>
                  <p><strong>Reviewed on:</strong> {new Date(review.reviewDate).toLocaleDateString()}</p>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default ReviewManagement;
