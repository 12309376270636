import React, { useEffect, useState, useCallback } from 'react';
import { Line } from 'react-chartjs-2'; 
import 'chart.js/auto';
import './RevenueData.css'; // Import the CSS file

const RevenueData = () => {
  const [userData, setUserData] = useState({});
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Fetch orders with useCallback
  const fetchOrders = useCallback(async () => {
    if (!userData._id) return; // Ensure userData is available
    try {
      const response = await fetch(`https://api.topcare.fashion/api/orders/seller/${userData._id}`, {
        headers: { 'auth-token': localStorage.getItem('auth-token') },
      });
      
      if (!response.ok) throw new Error('Failed to fetch orders');
      
      const data = await response.json();
      setOrders(data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch orders');
      setLoading(false);
    }
  }, [userData]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('https://api.topcare.fashion/api/user/profile', {
          headers: { 'auth-token': localStorage.getItem('auth-token') },
        });
        const data = await response.json();
        setUserData(data);
      } catch (err) {
        setError('Failed to fetch user data');
      }
    };

    fetchUserData();
  }, []);

  // Fetch orders when userData changes
  useEffect(() => {
    fetchOrders(); // Call fetchOrders whenever userData changes
  }, [fetchOrders]);

  if (loading) {
    return <p className="loading">Loading revenue data...</p>; // Use loading class
  }

  if (error) {
    return <p className="error">Error: {error}</p>; // Use error class
  }

  // Prepare data for the line chart
  const labels = orders.map((order) => `Product: ${order.productID}`); // Labels for x-axis (Order index)
  const billingAmounts = orders.map((order) => order.billingAmount); // Data for y-axis (Billing Amounts)

  // Calculate total revenue
  const totalRevenue = billingAmounts.reduce((total, amount) => total + amount, 0); 

  // Calculate cumulative revenue
  const cumulativeRevenue = billingAmounts.reduce((acc, amount, index) => {
    if (index === 0) {
      acc.push(amount); // First value is just the first amount
    } else {
      acc.push(acc[index - 1] + amount); // Add the current amount to the previous total
    }
    return acc;
  }, []);
  
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Cumulative Revenue in SGD',
        data: cumulativeRevenue,
        fill: false,
        backgroundColor: '#36A2EB',
        borderColor: '#36A2EB',
        tension: 0.1, // Makes the line smooth
      },
    ],
  };
  
  return (
    <div className="revenue-data-container"> {/* Apply container class */}
      <h2>Revenue Overview</h2>
      <h3>Lifetime Earnings: ${totalRevenue.toFixed(2)}</h3> {/* Display total revenue */}
      <div className="line-chart"> {/* Apply chart class */}
        <Line data={data} />
      </div>
    </div>
  );
};

export default RevenueData;
