import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import star_icon from "../Assets/star_icon.png"
import star_dull_icon from "../Assets/star_dull_icon.png"
import './ReviewBox.css';
import profile_img from '../Assets/profile_image.svg'

export const ReviewBox = ({ product, setAverageRating, setReviewCount }) => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rating, setRating] = useState(5); // Default rating
  const [comment, setComment] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [userReview, setUserReview] = useState(null); // Store the user's review
  const [userNames, setUserNames] = useState({}); // Store user names mapped by userID
  const [reviewSubmitted, setReviewSubmitted] = useState(false); // Flag to trigger re-fetch
  const navigate = useNavigate();

  // Fetch product reviews and user's review when the component mounts or reviewSubmitted changes
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        // Fetch reviews for the product
        const response = await fetch(`https://api.topcare.fashion/api/reviews/${product.id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch reviews");
        }
        const data = await response.json();
        setReviews(data);

        // Calculate average rating
        const totalRating = data.reduce((sum, review) => sum + review.rating, 0);
        const averageRating = data.length > 0 ? totalRating / data.length : 0;

        // Update review count and average rating in ProductDisplay
        setAverageRating(averageRating);
        setReviewCount(data.length);

        // Fetch user's existing review if available
        const userResponse = await fetch(`https://api.topcare.fashion/api/reviews/user/${product.id}`, {
          headers: {
            'auth-token': localStorage.getItem('auth-token'), 
          },
        });
        if (userResponse.ok) {
          const userReviewData = await userResponse.json();
          if (userReviewData) {
            setUserReview(userReviewData);
            setRating(userReviewData.rating);
            setComment(userReviewData.comment);
          }
        }

        // Fetch user names for each review
        const userIds = data.map(review => review.userID);
        const uniqueUserIds = [...new Set(userIds)];
        const userNamePromises = uniqueUserIds.map(userID =>
          fetch(`https://api.topcare.fashion/api/user/${userID}`)
            .then(response => response.json())
            .then(userData => ({ userID, name: userData.name }))
        );
        const userNameData = await Promise.all(userNamePromises);
        const userNamesMap = userNameData.reduce((acc, { userID, name }) => {
          acc[userID] = name;
          return acc;
        }, {});
        setUserNames(userNamesMap);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching reviews:', error);
        setLoading(false);
      }
    };

    fetchReviews();
  }, [product.id, setAverageRating, setReviewCount, reviewSubmitted]); // Added reviewSubmitted to dependency array

  // Handle submitting a new review or updating an existing one
  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);

    try {
      const url = userReview ? `https://api.topcare.fashion/api/reviews/update/${userReview._id}` : `https://api.topcare.fashion/api/reviews/add`;
      const method = userReview ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'auth-token': localStorage.getItem('auth-token'), 
        },
        body: JSON.stringify({
          productID: product.id,
          rating,
          comment,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        if (userReview) {
          // Update existing review
          setReviews((prevReviews) =>
            prevReviews.map((review) =>
              review._id === userReview._id ? data : review
            )
          );
        } else {
          // Add new review
          setReviews((prevReviews) => [...prevReviews, data]);
        }
        setComment(''); // Clear comment field
        setRating(5); // Reset rating
        setUserReview(null); // Reset userReview

        alert('Review has been submitted successfully!'); // Show alert
        navigate(`/product/${product.id}`);

        // Set reviewSubmitted to true to trigger re-fetch
        setReviewSubmitted(prev => !prev);
      } else {
        alert('Failed to submit review');
      }
    } catch (error) {
      console.error('Error submitting review:', error);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <div className="descriptionbox"> 
      <div className="descriptionbox-navigator">
        <div className="descriptionbox-nav-box">Reviews</div> 
      </div>

      <div className="descriptionbox-description">
        {loading ? (
          <p>Loading reviews...</p>
        ) : reviews.length === 0 ? (
          <p>No reviews yet for this product.</p>
        ) : (
          reviews.map((review) => (
            <div key={review._id} className="review-item">
              <img src={profile_img} alt="Profile" />
              <div className="review-content">
                <p><strong>{userNames[review.userID] || 'Loading...'}</strong></p>
                <div className="star-rating">
                  {[...Array(5)].map((_, index) => (
                    <img
                      key={index}
                      src={index < review.rating ? star_icon : star_dull_icon}
                      alt="Star"
                    />
                  ))}
                </div>
                <p> {review.comment}</p>
                <p><strong>Reviewed on:</strong> {new Date(review.reviewDate).toLocaleDateString()}</p>
              </div>
            </div>
          ))
        )}
      </div>

      
      {/* Add Review Form */}
      {localStorage.getItem('auth-token') ? (
      <div className="add-review">
        <h4>{userReview ? 'Update Review' : 'Leave a Review'}</h4>
        <form onSubmit={handleReviewSubmit}>
          <div className="rating-input">
            <label>Rating:</label>
            <div className="star-rating-input">
              {[...Array(5)].map((_, index) => (
                <img
                  key={index}
                  src={index < rating ? star_icon : star_dull_icon}
                  alt="Star"
                  onClick={() => setRating(index + 1)} // Update rating on star click
                  style={{ cursor: 'pointer', width: '25px', height: '25px' }}
                />
              ))}
            </div>
          </div>
          <br />
          <label>
            Comment:
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              rows="4"
              cols="50"
              placeholder="Write your review here"
            />
          </label>
          <br />
          <button type="submit" disabled={submitLoading}>
            {submitLoading ? 'Submitting...' : userReview ? 'Update' : 'Submit'}
          </button>
        </form>
      </div> )
      :
      <></>
      }
    </div>
  );
};
