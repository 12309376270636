import React, { createContext, useEffect, useState } from "react";

export const ShopContext = createContext(null);

const getDefaultCart = () => {
    let cart = {};
    for (let index = 0; index < 300 + 1; index++) {
        cart[index] = 0;
    }
    return cart;
};

const ShopContextProvider = (props) => {
    const [all_product, setAll_product] = useState([]);
    const [cartItems, setCartItems] = useState(getDefaultCart());
    const [points, setPoints] = useState(0);
    const [tier, setTier] = useState('Bronze');
    const [searchQuery, setSearchQuery] = useState("");
    const [favouriteClothing, setFavouriteClothing] = useState("");
    const [favouriteGender, setFavouriteGender] = useState("");

    // When page is loaded
    useEffect(() => {
        // Fetch all products API
        fetch('https://api.topcare.fashion/api/products/products')
        .then((response) => response.json())
        .then((data) => setAll_product(data));

        // If user is logged in, fetch their cart items and user info (points and tier)
        if (localStorage.getItem('auth-token')) {
            fetch('https://api.topcare.fashion/api/user/getcart', {
                method: 'POST',
                headers: {
                    Accept: 'application/form-data',
                    'auth-token': `${localStorage.getItem('auth-token')}`,
                    'Content-Type': 'application/json',
                },
                body: "",
            })
            .then((response) => response.json())
            .then((data) => setCartItems(data));

            // Fetch user points
            fetch('https://api.topcare.fashion/api/user/profile', {
                headers: {
                  'auth-token': localStorage.getItem('auth-token'),
                },
              })
                .then((res) => res.json())
                .then((data) => {
                    
                  setPoints(data.lifeTimePoints);
                  determineTier(data.lifeTimePoints);
                });
        }
    }, []);

    // Determine the user's tier based on points
    const determineTier = (points) => {
        if (points >= 1000) {
            setTier('Platinum');
        } else if (points >= 500) {
            setTier('Gold');
        } else if (points >= 100) {
            setTier('Silver');
        } else {
            setTier('Bronze');
        }
    };

    // Function to set search query
    const setSearch = (query) => {
        setSearchQuery(query);
    };

    // check if product is favourite API
    const isFavourite = async (itemId) => {
        try {
            const response = await fetch(`https://api.topcare.fashion/api/user/isfavourite/${itemId}`, {
                method: 'GET',
                headers: {
                    'auth-token': `${localStorage.getItem('auth-token')}`,
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            return data.isFavourite;
        } catch (error) {
            console.error("Error fetching favourite status:", error);
            return false;
        }
    };

    // Add to favourites API 
    const addToFavourites = (itemId) => {
        // If user is logged in, update favourites data in DB
        if (localStorage.getItem('auth-token')) {
            fetch('https://api.topcare.fashion/api/user/addtofavourites', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'auth-token': `${localStorage.getItem('auth-token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ itemId }),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data.message) {
                    console.log(data.message);
                }
            })
            .catch((error) => {
                console.error("Error adding to favourites:", error);
            });
        }
    };

    // Remove from favourites API
    const removeFromFavourites = (itemId) => {
        if (localStorage.getItem('auth-token')) {
            fetch('https://api.topcare.fashion/api/user/removefromfavourites', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'auth-token': `${localStorage.getItem('auth-token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ itemId }),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data.message) {
                    console.log(data.message);
                }
            })
            .catch((error) => {
                console.error("Error removing from favourites:", error);
            });
        }
    };

    // Add to cart API
    const addToCart = (itemId) => {
        setCartItems((prev) => ({...prev, [itemId]: prev[itemId] + 1}));

        if (localStorage.getItem('auth-token')) {
            fetch('https://api.topcare.fashion/api/user/addtocart', {
                method: 'POST',
                headers: {
                    Accept: 'application/form-data',
                    'auth-token': `${localStorage.getItem('auth-token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ "itemId": itemId }),
            })
            .then((response) => response.json())
            .then((data) => console.log(data));
        }
    };

    // Remove from cart API
    const removeFromCart = (itemId) => {
        setCartItems((prev) => ({...prev, [itemId]: prev[itemId] - 1}));

        if (localStorage.getItem('auth-token')) {
            fetch('https://api.topcare.fashion/api/user/removefromcart', {
                method: 'POST',
                headers: {
                    Accept: 'application/form-data',
                    'auth-token': `${localStorage.getItem('auth-token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ "itemId": itemId }),
            })
            .then((response) => response.json())
            .then((data) => console.log(data));
        }
    };
    
    // Function to clear the cart items after placing an order
    const clearCart = () => {
        setCartItems(getDefaultCart());
        
        // If user is logged in, clear the cart in the database as well
        if (localStorage.getItem('auth-token')) {
            fetch('https://api.topcare.fashion/api/user/clearcart', {
                method: 'POST',
                headers: {
                    'auth-token': `${localStorage.getItem('auth-token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({}),
            })
            .then((response) => response.json())
            .then((data) => console.log(data))
            .catch((error) => console.error("Error clearing cart:", error));
        }
    };

    const getTotalCartAmount = () => {
        let totalAmount = 0;

        for (const item in cartItems) {
            if(cartItems[item] > 0) {
                let itemInfo = all_product.find((product) => product.id === Number(item));
                totalAmount += itemInfo.new_price * cartItems[item];
            }
        }
        return totalAmount;
    };

    const getTotalCartItems = () => {
        let totalItem = 0;

        for(const item in cartItems) {
            if(cartItems[item] > 0) {
                totalItem += cartItems[item];
            }
        }
        return totalItem;
    };

    // mount into contextValue
    const contextValue = {
        getTotalCartItems,
        getTotalCartAmount,
        all_product,
        cartItems,
        points, 
        tier,   
        addToCart,
        removeFromCart,
        addToFavourites,
        removeFromFavourites,
        isFavourite,
        clearCart,
        setSearch,
        searchQuery,
        favouriteClothing,
        favouriteGender,
        setFavouriteClothing,
        setFavouriteGender,
    };

    return (
        <ShopContext.Provider value={contextValue}>
            {props.children}
        </ShopContext.Provider>
    );
};

export default ShopContextProvider;
