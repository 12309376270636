import React, { useEffect, useState } from 'react';
import './Popular.css';
import { Item } from '../Item/Item';

export const Popular = () => {
  const [popularProducts, setPopularProducts] = useState([]);
  const [menPopularProducts, setMenPopularProducts] = useState([]);

  useEffect(() => {
    fetch('https://api.topcare.fashion/api/products/popular_in_women')
      .then((response) => response.json())
      .then((data) => setPopularProducts(data));

    fetch('https://api.topcare.fashion/api/products/popular_in_men')
      .then((response) => response.json())
      .then((data) => setMenPopularProducts(data));
  }, []);

  return (
    <div className='popular'>
      <div className='popular-section'>
        <h1>POPULAR IN WOMEN</h1>
        <hr />
        <div className="popular-item">
          {popularProducts.map((item, i) => (
            <Item key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} amountSold={item.amountSold}/>
          ))}
        </div>
      </div>

      <div className='popular-section'>
        <h1>POPULAR IN MEN</h1>
        <hr />
        <div className="popular-item">
          {menPopularProducts.map((item, i) => (
            <Item key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} amountSold={item.amountSold}/>
          ))}
        </div>
      </div>
    </div>
  );
};
