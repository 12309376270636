// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popular {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px; /* Increased gap to separate sections clearly */
    padding: 20px;
    height: auto; /* Allow height to adjust based on content */
}

.popular-section {
    width: 100%;
    max-width: 1000x; /* Optional: Restrict maximum width */
    padding: 0 20px; /* Add padding to avoid content touching edges */
}

.popular h1 {
    color: #171717;
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
}

.popular hr {
    width: 200px;
    height: 6px;
    border-radius: 10px;
    background: #252525;
    margin: 0 auto 30px; /* Center hr and add bottom margin */
}

.popular-item {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Popular/Popular.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS,EAAE,+CAA+C;IAC1D,aAAa;IACb,YAAY,EAAE,4CAA4C;AAC9D;;AAEA;IACI,WAAW;IACX,gBAAgB,EAAE,qCAAqC;IACvD,eAAe,EAAE,gDAAgD;AACrE;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB,EAAE,oCAAoC;AAC7D;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,uBAAuB;AAC3B","sourcesContent":[".popular {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 60px; /* Increased gap to separate sections clearly */\n    padding: 20px;\n    height: auto; /* Allow height to adjust based on content */\n}\n\n.popular-section {\n    width: 100%;\n    max-width: 1000x; /* Optional: Restrict maximum width */\n    padding: 0 20px; /* Add padding to avoid content touching edges */\n}\n\n.popular h1 {\n    color: #171717;\n    font-size: 50px;\n    font-weight: 600;\n    margin-bottom: 10px;\n    text-align: center;\n}\n\n.popular hr {\n    width: 200px;\n    height: 6px;\n    border-radius: 10px;\n    background: #252525;\n    margin: 0 auto 30px; /* Center hr and add bottom margin */\n}\n\n.popular-item {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 30px;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
