import React, { useContext, useState } from 'react';
import { ShopContext } from '../Context/ShopContext';
import './CSS/ImageSearch.css'; // Add your CSS file for styling
import upload_area from '../Components/Assets/upload_area.svg';
import { Item } from '../Components/Item/Item'; // Import the Item component

const ImageSearch = () => {
    const [image, setImage] = useState(null);
    const [processingResult, setProcessingResult] = useState('');
    const [detectionInProgress, setDetectionInProgress] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('all'); // Default to 'all'
    const { all_product } = useContext(ShopContext);

    const imageHandler = async (e) => {
        const selectedImage = e.target.files[0];
        setImage(selectedImage);

        // Call image detection API after selecting the image
        if (selectedImage) {
            await detectProductType(selectedImage);
        }
    };

    const detectProductType = async (imageFile) => {
        let formData = new FormData();
        formData.append('file', imageFile);

        setDetectionInProgress(true);

        try {
            const response = await fetch('https://api.topcare.fashion/predict', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const text = await response.text();
            const jsonStringMatch = text.match(/\{.*\}/);
            if (jsonStringMatch) {
                const jsonString = jsonStringMatch[0];
                const jsonData = JSON.parse(jsonString);

                if (jsonData.category) {
                    const detectedCategory = jsonData.category.toLowerCase() === 'unknown' ? 'footwear' : jsonData.category.toLowerCase();
                    setProcessingResult(detectedCategory);
                } else {
                    setProcessingResult('footwear');
                }
            } else {
                setProcessingResult('footwear');
            }
        } catch (error) {
            console.error("Error detecting product type:", error);
            setProcessingResult('footwear');
        } finally {
            setDetectionInProgress(false);
        }
    };

    // Filter products based on processing result and selected gender category
    const filteredProducts = all_product.filter(item =>
        item.type === processingResult && 
        (selectedCategory === 'all' || item.category === selectedCategory)
    );

    return (
      <div>
        <div className="image-search">
            {/* Upload Area */}
            <div className="upload-area">
                <label htmlFor="file-input">
                    <img src={image ? URL.createObjectURL(image) : upload_area} className='upload-thumbnail' alt="Upload" />
                </label>
                <input onChange={imageHandler} type="file" name='image' id='file-input' hidden />
            </div>

            {/* Gender Filter */}
            <div className="gender-filter">
                <label htmlFor="category-select">Select Category: </label>
                <select
                    id="category-select"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                >
                    <option value="all">All</option>
                    <option value="men">Men</option>
                    <option value="women">Women</option>
                </select>
            </div>

            {/* Detection Progress Message */}
            {detectionInProgress && <p>Detecting product type...</p>}
            {processingResult && <p>Detected Product: {processingResult}</p>}
        </div>

        {/* Standalone Filtered Products Grid */}
        {processingResult && ( // Only show this section if processingResult is available
            <div className="filtered-products">
                <h1>Similar Products</h1>
                <hr />
                <div className="filtered-item"> {/* This div wraps your product items */}
                    {filteredProducts.length > 0 ? (
                        filteredProducts.map((item, i) => (
                            <Item key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} amountSold={item.amountSold} />
                        ))
                    ) : (
                        <p>No products found.</p> // Optional: A message if no products are found
                    )}
                </div>
            </div>
        )}
      </div>
    );
}

export default ImageSearch;
