// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.favourites-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.favourites-header {
    width: 100%;
    text-align: center; /* Center the header text */
    margin-bottom: 20px; /* Space between header and product grid */
}

.favourites-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Responsive grid */
    gap: 20px; /* Space between grid items */
    width: 100%; /* Ensure grid takes full width */
    max-width: 1200px; /* Optional: maximum width for better layout control */
}

.favourite-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.favourite-item:hover {
    transform: scale(1.05);
    transition: 0.6s;
}

.favourite-item-img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.favourite-item-info {
    margin-top: 10px;
}

.favourite-item-link {
    text-decoration: none; /* Remove underline from links */
    color: inherit; /* Use the text color of the parent element */
}

.favourite-item-link:focus,
.favourite-item-link:active {
    outline: none; /* Remove the default focus outline */
    background-color: transparent; /* Remove background color change on click */
}`, "",{"version":3,"sources":["webpack://./src/Pages/CSS/Favourites.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,kBAAkB,EAAE,2BAA2B;IAC/C,mBAAmB,EAAE,0CAA0C;AACnE;;AAEA;IACI,aAAa;IACb,4DAA4D,EAAE,oBAAoB;IAClF,SAAS,EAAE,6BAA6B;IACxC,WAAW,EAAE,iCAAiC;IAC9C,iBAAiB,EAAE,sDAAsD;AAC7E;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,qBAAqB,EAAE,gCAAgC;IACvD,cAAc,EAAE,6CAA6C;AACjE;;AAEA;;IAEI,aAAa,EAAE,qCAAqC;IACpD,6BAA6B,EAAE,4CAA4C;AAC/E","sourcesContent":[".favourites-container {\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.favourites-header {\n    width: 100%;\n    text-align: center; /* Center the header text */\n    margin-bottom: 20px; /* Space between header and product grid */\n}\n\n.favourites-list {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Responsive grid */\n    gap: 20px; /* Space between grid items */\n    width: 100%; /* Ensure grid takes full width */\n    max-width: 1200px; /* Optional: maximum width for better layout control */\n}\n\n.favourite-item {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n}\n\n.favourite-item:hover {\n    transform: scale(1.05);\n    transition: 0.6s;\n}\n\n.favourite-item-img {\n    width: 100%;\n    height: 300px;\n    object-fit: cover;\n}\n\n.favourite-item-info {\n    margin-top: 10px;\n}\n\n.favourite-item-link {\n    text-decoration: none; /* Remove underline from links */\n    color: inherit; /* Use the text color of the parent element */\n}\n\n.favourite-item-link:focus,\n.favourite-item-link:active {\n    outline: none; /* Remove the default focus outline */\n    background-color: transparent; /* Remove background color change on click */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
