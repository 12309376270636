// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    gap: 20px;
    width: 100%;
    max-width: 250px;
    height: 100vh;
    background: #242566;
}

.sidebar-item {
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 0px 20px;
    padding: 5px 10px;
    border-radius: 6px;
    color: #242566;
    background: #ffd0cb;
    gap: 20px;
    cursor: pointer;
}

.sidebar-item:hover {
    transform: scale(1.05);
    transition: 0.6s;
}

.sidebar-item img {
    width: 50px;
}`, "",{"version":3,"sources":["webpack://./src/Seller/Components/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,mBAAmB;IACnB,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".sidebar {\n    display: flex;\n    flex-direction: column;\n    padding-top: 30px;\n    gap: 20px;\n    width: 100%;\n    max-width: 250px;\n    height: 100vh;\n    background: #242566;\n}\n\n.sidebar-item {\n    display: flex;\n    align-items: center;\n    justify-content: start;\n    margin: 0px 20px;\n    padding: 5px 10px;\n    border-radius: 6px;\n    color: #242566;\n    background: #ffd0cb;\n    gap: 20px;\n    cursor: pointer;\n}\n\n.sidebar-item:hover {\n    transform: scale(1.05);\n    transition: 0.6s;\n}\n\n.sidebar-item img {\n    width: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
