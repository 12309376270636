import React, { useEffect, useState } from 'react'
import './CSS/NewArrivals.css'
import { Item } from '../Components/Item/Item'


export const NewArrivals = () => {

  const [new_collection, setNew_collection] = useState([]);

  useEffect(() => {
    fetch('https://api.topcare.fashion/api/products/newcollection')
    .then((response)=> response.json())
    .then((data)=> setNew_collection(data));
  },[])

  return (
    <div className='new-collections'>
        <h1>NEW IN STORE</h1>
        <hr/>
        <div className="collections">
            {new_collection.map((item, i) => {
                return <Item key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price} amountSold={item.amountSold}/>
            })}
        </div>
    </div>
  )
}
