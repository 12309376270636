import React, { useEffect, useState } from 'react';
import './ListProduct.css';
import cross_icon from '../../assets/cross_icon.png';

const ListProduct = () => {
  const [allproducts, setAllProducts] = useState([]);
  const [editingProductId, setEditingProductId] = useState(null);
  const [editingFields, setEditingFields] = useState({});

  // Fetch All products from API
  const fetchInfo = async () => {
    try {
      const response = await fetch('https://api.topcare.fashion/api/products/myproducts', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'auth-token': `${localStorage.getItem('auth-token')}`, // Include the token
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setAllProducts(data.products || []);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const handleFieldChange = (id, field, value) => {
    setEditingFields((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: value,
      },
    }));
  };

  const saveChanges = async (id) => {
    if (!editingFields[id]) return;

    const updatedProduct = {
      ...allproducts.find((product) => product.id === id),
      ...editingFields[id],
    };

    try {
      const response = await fetch('https://api.topcare.fashion/api/products/updateproduct', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedProduct),
      });

      if (!response.ok) {
        throw new Error('Failed to update product');
      }

      setAllProducts((prev) =>
        prev.map((product) => (product.id === id ? updatedProduct : product))
      );

      setEditingProductId(null); // Clear editing state
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  const remove_product = async (id) => {
    await fetch('https://api.topcare.fashion/api/products/removeproduct', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: id }),
    });
    await fetchInfo();
  };

  return (
    <div className="list-product">
    <h1>My Product List</h1>

    <table className="listproduct-table">
      <thead>
        <tr>
          <th>Product</th>
          <th>Title</th>
          <th>Price</th>
          <th>Description</th>
          <th>Category</th>
          <th>Type</th>
          <th>Quantity</th>
          <th>Status</th>
          <th>No. Of Favourites</th>
          <th>Remove</th>
        </tr>
      </thead>

      <tbody>
        {allproducts.map((product, index) => (
          <tr key={index}>
            <td>
              <img src={product.image} alt="" className="listproduct-product-icon" />
            </td>

            {/* Editable Title */}
            <td>
              <input
                type="text"
                value={editingProductId === product.id ? editingFields[product.id]?.name || product.name : product.name}
                onChange={(e) => handleFieldChange(product.id, 'name', e.target.value)}
                onFocus={() => setEditingProductId(product.id)}
                onBlur={() => saveChanges(product.id)}
                className="editable-field"
              />
            </td>

            {/* Editable Price */}
            <td>
              <input
                type="number"
                value={editingProductId === product.id ? editingFields[product.id]?.new_price || product.new_price : product.new_price}
                onChange={(e) => handleFieldChange(product.id, 'new_price', e.target.value)}
                onFocus={() => setEditingProductId(product.id)}
                onBlur={() => saveChanges(product.id)}
                className="editable-field"
              />
            </td>

            {/* Editable Description (textarea) */}
            <td>
              <textarea
                value={editingProductId === product.id ? editingFields[product.id]?.description || product.description : product.description}
                onChange={(e) => handleFieldChange(product.id, 'description', e.target.value)}
                onFocus={() => setEditingProductId(product.id)}
                onBlur={() => saveChanges(product.id)}
                className="editable-textarea"
              />
            </td>

            {/* Editable Category Dropdown */}
            <td>
              <select
                value={editingProductId === product.id ? editingFields[product.id]?.category || product.category : product.category}
                onChange={(e) => handleFieldChange(product.id, 'category', e.target.value)}
                onFocus={() => setEditingProductId(product.id)}
                onBlur={() => saveChanges(product.id)}
                className="editable-field"
              >
                <option value="women">Women</option>
                <option value="men">Men</option>
              </select>
            </td>

            {/* Editable Type Dropdown */}
            <td>
              <select
                value={editingProductId === product.id ? editingFields[product.id]?.type || product.type : product.type}
                onChange={(e) => handleFieldChange(product.id, 'type', e.target.value)}
                onFocus={() => setEditingProductId(product.id)}
                onBlur={() => saveChanges(product.id)}
                className="editable-field"
              >
                <option value="top">Top</option>
                <option value="bottom">Bottom</option>
                <option value="footwear">Footwear</option>
                <option value="accessory">Accessory</option>
              </select>
            </td>

             {/* Editable quantity */}
             <td>
              <input
                type="number"
                value={editingProductId === product.id ? editingFields[product.id]?.quantity || product.quantity : product.quantity}
                onChange={(e) => handleFieldChange(product.id, 'quantity', e.target.value)}
                onFocus={() => setEditingProductId(product.id)}
                onBlur={() => saveChanges(product.id)}
                className="editable-field"
              />
            </td>

            {/* Product Status */}
            <td>
              {product.available ? 'Available' : 'Out of Stock'}
            </td>
            
            <td>
              {product.favourite}
            </td>

            <td>
              <img
                onClick={() => remove_product(product.id)}
                className="listproduct-remove-icon"
                src={cross_icon}
                alt=""
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  );
};

export default ListProduct;
