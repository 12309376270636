// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item {
    width: 350px;
    background-color: #ffffff; /* Add a white background to separate from background */
    border-radius: 8px; /* Add rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft box shadow */
    padding: 16px; /* Add padding inside the item */
    transition: transform 0.6s, box-shadow 0.6s; /* Smooth transition for hover effect */
    margin-bottom: 20px; /* Add some margin to create space between items */
}

.item a {
    margin: 6px 0px;
    text-decoration: none;
    color: inherit;
}

.item p {
    font-weight: bold;
    font-size: 18px;
}

.item-prices {
    display: flex;
    gap: 20px; 
}

.item-prices-new {
    color: #374151;
    font-size: 18px;
    font-weight: 600;
}

.item-prices-old {
    color: #8c8c8c;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}

.item:hover {
    transform: scale(1.05); /* Enlarge slightly on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Deepen shadow on hover */
}

.item-rating {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.star-icon {
    width: 16px;
    height: 16px;
    margin-right: 4px; /* Add some space between stars */
}
`, "",{"version":3,"sources":["webpack://./src/Components/Item/Item.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB,EAAE,uDAAuD;IAClF,kBAAkB,EAAE,wBAAwB;IAC5C,wCAAwC,EAAE,oBAAoB;IAC9D,aAAa,EAAE,gCAAgC;IAC/C,2CAA2C,EAAE,uCAAuC;IACpF,mBAAmB,EAAE,kDAAkD;AAC3E;;AAEA;IACI,eAAe;IACf,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,6BAA6B;AACjC;;AAEA;IACI,sBAAsB,EAAE,8BAA8B;IACtD,yCAAyC,EAAE,2BAA2B;AAC1E;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB,EAAE,iCAAiC;AACxD","sourcesContent":[".item {\n    width: 350px;\n    background-color: #ffffff; /* Add a white background to separate from background */\n    border-radius: 8px; /* Add rounded corners */\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft box shadow */\n    padding: 16px; /* Add padding inside the item */\n    transition: transform 0.6s, box-shadow 0.6s; /* Smooth transition for hover effect */\n    margin-bottom: 20px; /* Add some margin to create space between items */\n}\n\n.item a {\n    margin: 6px 0px;\n    text-decoration: none;\n    color: inherit;\n}\n\n.item p {\n    font-weight: bold;\n    font-size: 18px;\n}\n\n.item-prices {\n    display: flex;\n    gap: 20px; \n}\n\n.item-prices-new {\n    color: #374151;\n    font-size: 18px;\n    font-weight: 600;\n}\n\n.item-prices-old {\n    color: #8c8c8c;\n    font-size: 18px;\n    font-weight: 500;\n    text-decoration: line-through;\n}\n\n.item:hover {\n    transform: scale(1.05); /* Enlarge slightly on hover */\n    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Deepen shadow on hover */\n}\n\n.item-rating {\n    display: flex;\n    align-items: center;\n    margin-top: 10px;\n}\n\n.star-icon {\n    width: 16px;\n    height: 16px;\n    margin-right: 4px; /* Add some space between stars */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
