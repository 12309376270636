import React, { useContext, useEffect, useState } from 'react'
import './ProductDisplay.css'
import star_icon from "../Assets/star_icon.png"
import star_dull_icon from "../Assets/star_dull_icon.png"
import { ShopContext } from '../../Context/ShopContext'
import favourite_icon from "../Assets/favourite_icon.svg"
import favourite_icon_filled from "../Assets/favourite_icon_filled.svg"
import { Link } from 'react-router-dom'

export const ProductDisplay = (props) => {
    
    const { product, averageRating, reviewCount } = props;

    const {addToCart, addToFavourites, removeFromFavourites, isFavourite } = useContext(ShopContext);

    const [isFav, setIsFav] = useState(false);

    const renderStars = () => {
        const filledStars = Math.floor(averageRating);
        const emptyStars = 5 - filledStars;
        
        return (
          <>
            {Array(filledStars).fill().map((_, i) => (
              <img key={i} src={star_icon} alt="Filled Star" />
            ))}
            {Array(emptyStars).fill().map((_, i) => (
              <img key={i} src={star_dull_icon} alt="Dull Star" />
            ))}
          </>
        );
      };

    useEffect(() => {
        const checkIfFavourite = async () => {
            const result = await isFavourite(product.id);
            setIsFav(result);
        };
        checkIfFavourite();
    }, [product.id, isFavourite]);

    const handleFavouriteClick = () => {
        if (isFav) {
            removeFromFavourites(product.id);
        } else {
            addToFavourites(product.id);
        }
        setIsFav(!isFav);
    };

  return (
    <div className='productdisplay'>
        <div className="productdisplay-left">
            <div className="productdisplay-img">
                <img className='productdisplay-main-img' src={product.image} alt="" />
            </div>
        </div>

        <div className="productdisplay-right">
            <h1>{product.name}</h1>
            <div className="productdisplay-right-stars">
                {renderStars()}
                <p>({reviewCount} reviews)</p>
            </div>

            <div className="productdisplay-availability">
                {product.available ? (
                    <div className="available">{product.quantity} left</div>
                ) : (
                    <div className="out-of-stock">Out of Stock</div>
                )}
            </div>

            <div className="productdisplay-right-prices">
                <div className="productdisplay-right-price-new">$ {product.new_price}</div>
            </div>

            <div className="productdisplay-right-description">
                {product.description}
            </div>

            <div className="cart-favourite">
                {localStorage.getItem('auth-token') ? (
                    <button 
                    onClick={() => {addToCart(product.id)}} 
                    disabled={!product.available} // Disable the button if product is not available
                    >
                    {product.available ? 'ADD TO CART' : 'OUT OF STOCK'}
                    </button>
                ) : (
                    <Link to='/login'><button>ADD TO CART</button></Link>
                )}

                {localStorage.getItem('auth-token') ? (
                    <img
                        src={isFav ? favourite_icon_filled : favourite_icon}
                        alt="Add to Favourite"
                        onClick={handleFavouriteClick}
                    /> 
                ) : (
                    <Link to='/login'><img src={favourite_icon} alt='' /></Link>
                )}
            </div>

            <p className='productdisplay-right-category'><span>Category: </span>{product.category}, {product.type}</p>
        </div>
    </div>
  )
}
