import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './CSS/ChatList.css'; 
import chat_profile_icon from '../Components/Assets/chat_profile_icon.svg';

const ChatList = () => {
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState('');

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const response = await fetch('https://api.topcare.fashion/api/chat/conversations', {
          headers: {
            'auth-token': localStorage.getItem('auth-token'),
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch conversations');
        }

        const data = await response.json();
        setConversations(data.conversations);
        setUserId(data.userId); // Retrieve userId from the API response
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchConversations();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="chat-list-container">
        <h2 className="chat-list-title">Your Conversations</h2>
        {conversations.length > 0 ? (
            <ul className="chat-list">
            {conversations.map((conversation) => {
                const otherParticipant = conversation.participants.find(participant => participant._id !== userId);
                return (
                <li key={conversation._id} className="chat-list-item">
                    <Link to={`/chatwithseller?sellerID=${otherParticipant._id}`} className="chat-link">
                    <img src={chat_profile_icon} alt="" className="chat-profile-icon" />{otherParticipant.name}
                    </Link>
                </li>
                );
            })}
            </ul>
        ) : (
            <p className="no-conversations">No conversations found.</p>
        )}
    </div>
  );
};

export default ChatList;
