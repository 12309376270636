import React, { useState, useContext, useEffect } from 'react';
import { ShopContext } from '../Context/ShopContext';
import { useParams } from 'react-router-dom';
import { Breadcrum } from '../Components/Breadcrums/Breadcrum';
import { ProductDisplay } from '../Components/ProductDisplay/ProductDisplay';
import { ReviewBox } from '../Components/ReviewBox/ReviewBox';
import { RelatedProducts } from '../Components/RelatedProducts/RelatedProducts';
import { SellerProfile } from '../Components/SellerProfile/SellerProfile';

export const Product = () => {
  const { all_product, setFavouriteClothing, setFavouriteGender } = useContext(ShopContext); 
  const { productId } = useParams(); // get product ID 
  const [averageRating, setAverageRating] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const product = all_product.find((e) => e.id === Number(productId));

  // Use useEffect to trigger the functions when the component mounts (i.e., user clicks into the product)
  useEffect(() => {
    if (product) {
      setFavouriteClothing(product.type);  // set the favourite clothing based on the product type
      setFavouriteGender(product.category);  // set the favourite gender based on the product category
    }
  }, [product, setFavouriteClothing, setFavouriteGender]); // depend on product and set functions

  return (
    <div>
      <Breadcrum product={product} />
      <ProductDisplay 
        product={product} 
        averageRating={averageRating} 
        reviewCount={reviewCount} 
      />
      <SellerProfile product={product}/>
      <ReviewBox 
        product={product} 
        setAverageRating={setAverageRating} 
        setReviewCount={setReviewCount}
      />
      <RelatedProducts category={product?.category} type={product?.type}/>
    </div>
  );
};
