import React from 'react'
import './Sidebar.css'
import { Link, useNavigate } from 'react-router-dom';
import add_product_icon from '../../assets/Product_Cart.svg'
import list_product_icon from '../../assets/Product_list_icon.svg'
import orders_icon from '../../assets/orders_icon.svg'
import revenue_icon from '../../assets/revenue_icon.svg'
import review_icon from '../../assets/review_icon.svg'
import chat_icon from '../../assets/chat_icon.svg'
import back_icon from '../../assets/back_icon.svg'

const Sidebar = () => {
    const navigate = useNavigate();
  
    const handleRedirect = () => {
      // Redirect to '/'
      navigate('/');
      // Refresh the page
      window.location.reload();
    };

  return (
    <div className='sidebar'>
        <div className="sidebar-item" onClick={handleRedirect}>
            <img src={back_icon} alt="" />
            <p>Back to Marketplace</p>
        </div>
        
        <Link to='revenuedata' style={{textDecoration: "none"}}>
            <div className="sidebar-item">
                <img src={revenue_icon} alt="" />
                <p>Revenue Data</p>
            </div>
        </Link>

        <Link to='addproduct' style={{textDecoration: "none"}}>
            <div className="sidebar-item">
                <img src={add_product_icon} alt="" />
                <p>Add product</p>
            </div>
        </Link>

        <Link to='listproduct' style={{textDecoration: "none"}}>
            <div className="sidebar-item">
                <img src={list_product_icon} alt="" />
                <p>My Product list</p>
            </div>
        </Link>

        <Link to='orders' style={{textDecoration: "none"}}>
            <div className="sidebar-item">
                <img src={orders_icon} alt="" />
                <p>Orders</p>
            </div>
        </Link>

        <Link to='reviewmanagement' style={{textDecoration: "none"}}>
            <div className="sidebar-item">
                <img src={review_icon} alt="" />
                <p>Review Management</p>
            </div>
        </Link>

        <Link to='chatmanagement' style={{textDecoration: "none"}}>
            <div className="sidebar-item">
                <img src={chat_icon} alt="" />
                <p>Chat Management</p>
            </div>
        </Link>
    </div>
  )
}

export default Sidebar