import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './Navbar.css'
import navlogo from '../../assets/logo.png'
import navProfile from '../../assets/nav-profile.svg' 

const Navbar = () => {

  // State to manage dropdown visibility
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  // Function to toggle dropdown visibility
  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  return (
    <div className='navbar'>
      <Link to='/sellerpanel'>
        <img src={navlogo} alt="" className="nav-logo" />
      </Link>
      <p>Seller Panel</p>
      <div className="nav-profile">
            <img src={navProfile} alt="Profile" onClick={toggleDropdown} />
            {isDropdownVisible && (
              <div className="dropdown-menu">
                <Link to='/profile'>Profile</Link>
                <Link onClick={()=>{localStorage.removeItem('auth-token'); window.location.replace('/')}} >Logout</Link>
              </div>
            )}
      </div>
    </div>
  );
};

export default Navbar;