import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ShopContext } from '../Context/ShopContext';
import './CSS/OrderList.css';

const OrderList = () => {
    const { all_product } = useContext(ShopContext); // Access all products from ShopContext
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch orders from the backend
        fetch('https://api.topcare.fashion/api/orders/orders', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'auth-token': localStorage.getItem('auth-token'), // Include user authentication token
            },
        })
        .then(response => response.json())
        .then(data => {
            setOrders(data);
            setLoading(false);
        })
        .catch(error => {
            console.error('Error fetching orders:', error);
            setLoading(false);
        });
    }, []);

    const handleReceived = async (orderId) => {
        try {
            const response = await fetch(`https://api.topcare.fashion/api/orders/${orderId}/received`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': localStorage.getItem('auth-token'),
                },
            });

            if (response.ok) {
                setOrders(prevOrders => 
                    prevOrders.map(order => 
                        order._id === orderId ? { ...order, status: 'received' } : order
                    )
                );
            }
        } catch (error) {
            console.error('Error updating order status to received:', error);
        }
    };

    const handleReturnRefund = (orderId) => {
        navigate(`/return-product/${orderId}`);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="order-list">
            <h2>Order History</h2>
            {orders.length === 0 ? (
                <p>No orders found.</p>
            ) : (
                <ul>
                    {orders.map(order => {
                        // Find the product for each order
                        const product = all_product.find(p => p._id === order.productID);
                        return (
                            <li key={order._id} className="order-item">
                                {product ? (
                                    <>
                                        <Link to={`/product/${product.id}`}>
                                            <img src={product.image} alt={product.name} className="product-image" />
                                        </Link>
                                        <p>
                                            <Link to={`/product/${product.id}`}> {product.name}</Link>
                                        </p>
                                        <p>Quantity: {order.quantity}</p>
                                        <p>Billing Amount: ${order.billingAmount}</p>
                                        
                                        {/* Conditional styling for status */}
                                        <p>
                                            Status: <br />   
                                            <span style={{
                                                color: order.status === 'delivered' ? 'green' :
                                                    order.status === 'canceled' ? 'red' :
                                                    order.status === 'received' ? 'blue' :
                                                    order.status === 'return requested' ? 'orange' : 'black'
                                            }}> 
                                                {order.status}
                                            </span>
                                        </p>

                                        <p>Ordered On: {new Date(order.orderDate).toLocaleDateString()}</p>
                                        <p>Tracking Number: {order._id}</p>

                                        {/* Buttons for order actions */}
                                        {order.status === 'delivered' && (
                                            <>
                                                <button 
                                                    className="received-button"
                                                    onClick={() => handleReceived(order._id)}
                                                >
                                                    Mark as Received
                                                </button>

                                                <button 
                                                    className="return-refund-button"
                                                    onClick={() => handleReturnRefund(order._id)}
                                                >
                                                    Request Return/Refund
                                                </button>
                                            </>
                                        )}
                                    </>
                                ) : null }
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default OrderList;
