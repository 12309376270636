import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Navbar } from './Components/Navbar/Navbar';
import { Shop } from './Pages/Shop';
import { ShopCategory } from './Pages/ShopCategory';
import { Product } from './Pages/Product';
import { Cart } from './Pages/Cart';
import { LoginSignUp } from './Pages/LoginSignUp';
import { Footer } from './Components/Footer/Footer';
import men_banner from './Components/Assets/banner_mens.png';  // sample banner
import women_banner from './Components/Assets/banner_women.png'; // sample banner
import SellerPanel from './Seller/SellerPanel';
import Profile  from './Pages/Profile';
import Favourites from './Pages/Favourites';
import EditProfile  from './Pages/EditProfile';
import SearchResults from './Pages/SearchResults';
import  ChangePassword  from './Pages/ChangePassword';
import CheckOut  from './Pages/CheckOut';
import OrderList from './Pages/OrderList';
import ChatWithSeller from './Pages/ChatWithSeller';
import ChatList from './Pages/ChatList';
import BrandLoyalty from './Pages/BrandLoyalty';
import ImageSearch from './Pages/ImageSearch';
import MixMatch from './Pages/MixMatch';
import { NewArrivals } from './Pages/NewArrivals';
import ReturnPolicy from './Pages/ReturnPolicy';
import ReturnProduct from './Pages/ReturnProduct';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* Default Layout */}
          <Route path='/' element={<><Navbar /><Shop /><Footer /></>} />
          <Route path='/men' element={<><Navbar /><ShopCategory banner={men_banner} category="men" /><Footer /></>} />
          <Route path='/women' element={<><Navbar /><ShopCategory banner={women_banner} category="women" /><Footer /></>} />
          <Route path='/product'>
            <Route path=":productId" element={<><Navbar /><Product /><Footer /></>} />
          </Route>
          <Route path='/cart' element={<><Navbar /><Cart /><Footer /></>} />
          <Route path='/login' element={<><Navbar /><LoginSignUp /><Footer /></>} />

          <Route path='/profile' element={<><Navbar /><Profile /><Footer /></>} />
          <Route path='/favourites' element={<><Navbar /><Favourites /><Footer /></>} />
          <Route path='/editprofile' element={<><Navbar /> <EditProfile /> <Footer /></>} />
          <Route path='/changepassword' element={<><Navbar /> <ChangePassword /> <Footer /></>} />
          <Route path='/brandloyalty' element={<><Navbar /> <BrandLoyalty/> <Footer /></>} />
          <Route path='/mixmatch' element={<><Navbar /> <MixMatch/> <Footer /></>} />
          <Route path='/newarrivals' element={<><Navbar /> <NewArrivals/> <Footer /></>} />
          <Route path='/returnpolicy' element={<><Navbar /> <ReturnPolicy/> </>} />
          <Route path='/return-product/:orderId' element={<><Navbar /> <ReturnProduct/> <Footer/> </>} />
          
          <Route path='/search' element={<><Navbar /> <SearchResults /> <Footer /></>} />
          <Route path='/imagesearch' element={<><Navbar /> <ImageSearch/> <Footer /></>} />
          <Route path='/checkout' element={<><Navbar /> <CheckOut /> <Footer /></>} />
          <Route path='/orders' element={<><Navbar /> <OrderList /> <Footer /></>} />
          <Route path='/chatwithseller' element={<><Navbar /> <ChatWithSeller/> </>} />
          <Route path='/chatlist' element={<><Navbar /> <ChatList/> </>} />

          {/* Seller Panel Layout */}
          <Route path='/sellerpanel/*' element={<SellerPanel />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
