import React, { Suspense, useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { 
  OrbitControls, 
  Stage,
  useGLTF,
  Center,
  Html,
  useProgress
} from '@react-three/drei';
import './VirtualMannequin.css';

const Loader = () => {
  const { progress } = useProgress();
  return <Html center>{progress} % loaded</Html>;
};

const Model = ({ url, position, rotation, scale = 1 }) => {
  const { scene } = useGLTF(url);
  return (
    <primitive 
      object={scene.clone()} 
      position={position}
      rotation={[rotation[0], rotation[1] + Math.PI, rotation[2]]} // Adjust Y-axis rotation by adding Math.PI
      scale={[scale, scale, scale]}
    />
  );
};


const PositionControls = ({ label, position, rotation, scale, onPositionChange, onRotationChange, onScaleChange }) => {
  return (
    <div className="position-controls">
      <h4>{label} Position & Rotation Controls</h4>
      
      {/* Position Controls */}
      <div className="control-section">
        <h5>Position</h5>
        <div className="slider-control">
          <label>Height (Y)</label>
          <input
            type="range"
            min="-2"
            max="2"
            step="0.1"
            value={position[1]}
            onChange={(e) => onPositionChange([position[0], parseFloat(e.target.value), position[2]])}
          />
          <span>{position[1].toFixed(1)}</span>
        </div>

        <div className="slider-control">
          <label>Forward/Back (Z)</label>
          <input
            type="range"
            min="-2"
            max="2"
            step="0.1"
            value={position[2]}
            onChange={(e) => onPositionChange([position[0], position[1], parseFloat(e.target.value)])}
          />
          <span>{position[2].toFixed(1)}</span>
        </div>

        <div className="slider-control">
          <label>Left/Right (X)</label>
          <input
            type="range"
            min="-2"
            max="2"
            step="0.1"
            value={position[0]}
            onChange={(e) => onPositionChange([parseFloat(e.target.value), position[1], position[2]])}
          />
          <span>{position[0].toFixed(1)}</span>
        </div>
      </div>

      {/* Scale Controls */}
      <div className="control-section">
        <h5>Scale</h5>
        <div className="slider-control">
          <label>Size</label>
          <input
            type="range"
            min="0.5"
            max="2.5"
            step="0.1"
            value={scale}
            onChange={(e) => onScaleChange(parseFloat(e.target.value))}
          />
          <span>{scale.toFixed(1)}x</span>
        </div>
      </div>

      {/* Rotation Controls */}
      <div className="control-section">
        <h5>Rotation</h5>
        <div className="slider-control">
          <label>Tilt (X)</label>
          <input
            type="range"
            min="-3.14"
            max="3.14"
            step="0.1"
            value={rotation[0]}
            onChange={(e) => onRotationChange([parseFloat(e.target.value), rotation[1], rotation[2]])}
          />
          <span>{(rotation[0] * 57.3).toFixed(0)}°</span>
        </div>

        <div className="slider-control">
          <label>Spin (Y)</label>
          <input
            type="range"
            min="-3.14"
            max="3.14"
            step="0.1"
            value={rotation[1]}
            onChange={(e) => onRotationChange([rotation[0], parseFloat(e.target.value), rotation[2]])}
          />
          <span>{(rotation[1] * 57.3).toFixed(0)}°</span>
        </div>

        <div className="slider-control">
          <label>Roll (Z)</label>
          <input
            type="range"
            min="-3.14"
            max="3.14"
            step="0.1"
            value={rotation[2]}
            onChange={(e) => onRotationChange([rotation[0], rotation[1], parseFloat(e.target.value)])}
          />
          <span>{(rotation[2] * 57.3).toFixed(0)}°</span>
        </div>
      </div>

      {/* Reset Buttons */}
      <div className="reset-buttons">
        <button 
          onClick={() => {
            onPositionChange([0, position[1], 0]);
            onRotationChange([0, 0, 0]);
            onScaleChange(1);
          }} 
          className="reset-button"
        >
          Reset All
        </button>
      </div>
    </div>
  );
};

const Scene = ({ selectedTop, selectedBottom, topPosition, bottomPosition, topRotation, bottomRotation }) => {
  return (
    <Stage environment="city" intensity={0.5}>
      <Center>
        {selectedTop && (
          <Model
            url={selectedTop.modelUrl}
            position={topPosition}
            rotation={topRotation}
            scale={1.5}
          />
        )}
        {selectedBottom && (
          <Model
            url={selectedBottom.modelUrl}
            position={bottomPosition}
            rotation={bottomRotation}
            scale={1.5}
          />
        )}
      </Center>
    </Stage>
  );
};

console.log(Scene);


const VirtualMannequin = ({ selectedTop, selectedBottom, selectedFootwear }) => {
  const [topPosition, setTopPosition] = useState([0, 0, 0]); // lowered top Y position to 0.2
  const [bottomPosition, setBottomPosition] = useState([0, -1, 0]);
  const [footwearPosition, setFootwearPosition] = useState([0, -1.8, 0]);

  const [topRotation, setTopRotation] = useState([0, -0.2, 0]);
  const [bottomRotation, setBottomRotation] = useState([0, -0.3, 0]);
  const [footwearRotation, setFootwearRotation] = useState([0, -0.3, 0]);

  const [topScale, setTopScale] = useState(1);
  const [bottomScale, setBottomScale] = useState(1);
  const [footwearScale, setFootwearScale] = useState(0.6);
  const [activeModel, setActiveModel] = useState(null);

  useEffect(() => {
    // Set active model based on last selected item
    if (selectedTop) setActiveModel('top');
    if (selectedBottom) setActiveModel('bottom');
    if (selectedFootwear) setActiveModel('footwear');
  }, [selectedTop, selectedBottom, selectedFootwear]);

  return (
    <div className="virtual-mannequin-layout">
      <div className="model-viewer">
        <Canvas
          camera={{ position: [0, 0, 4], fov: 50 }}
          style={{ height: '600px' }}
        >
          <Suspense fallback={<Loader />}>
            <Stage environment="city" intensity={0.5}>
              <Center>
                {selectedTop && (
                  <Model
                    url={selectedTop.modelUrl}
                    position={topPosition}
                    rotation={topRotation}
                    scale={topScale}
                    onClick={() => setActiveModel('top')}
                  />
                )}
                {selectedBottom && (
                  <Model
                    url={selectedBottom.modelUrl}
                    position={bottomPosition}
                    rotation={bottomRotation}
                    scale={bottomScale}
                    onClick={() => setActiveModel('bottom')}
                  />
                )}
                {selectedFootwear && (
                  <Model
                    url={selectedFootwear.modelUrl}
                    position={footwearPosition}
                    rotation={footwearRotation}
                    scale={footwearScale}
                    onClick={() => setActiveModel('footwear')}
                  />
                )}
              </Center>
            </Stage>
            <OrbitControls
              enablePan={true}
              enableZoom={true}
              enableRotate={true}
            />
          </Suspense>
        </Canvas>
      </div>

      <div className="controls-sidebar">
        {activeModel === 'top' && selectedTop && (
          <PositionControls
            label="Top Position & Rotation Controls"
            position={topPosition}
            rotation={topRotation}
            scale={topScale}
            onPositionChange={setTopPosition}
            onRotationChange={setTopRotation}
            onScaleChange={setTopScale}
          />
        )}

        {activeModel === 'bottom' && selectedBottom && (
          <PositionControls
            label="Bottom Position & Rotation Controls"
            position={bottomPosition}
            rotation={bottomRotation}
            scale={bottomScale}
            onPositionChange={setBottomPosition}
            onRotationChange={setBottomRotation}
            onScaleChange={setBottomScale}
          />
        )}

        {activeModel === 'footwear' && selectedFootwear && (
          <PositionControls
            label="Footwear Position & Rotation Controls"
            position={footwearPosition}
            rotation={footwearRotation}
            scale={footwearScale}
            onPositionChange={setFootwearPosition}
            onRotationChange={setFootwearRotation}
            onScaleChange={setFootwearScale}
          />
        )}

        <div className="model-selector">
          {selectedTop && (
            <button 
              className={`model-select-btn ${activeModel === 'top' ? 'active' : ''}`}
              onClick={() => setActiveModel('top')}
            >
              Top Controls
            </button>
          )}
          {selectedBottom && (
            <button 
              className={`model-select-btn ${activeModel === 'bottom' ? 'active' : ''}`}
              onClick={() => setActiveModel('bottom')}
            >
              Bottom Controls
            </button>
          )}
          {selectedFootwear && (
            <button 
              className={`model-select-btn ${activeModel === 'footwear' ? 'active' : ''}`}
              onClick={() => setActiveModel('footwear')}
            >
              Footwear Controls
            </button>
          )}
        </div>

        <div className="controls-help">
          <h3>Viewer Controls:</h3>
          <ul>
            <li>🖱️ Left Click + Drag: Rotate camera</li>
            <li>🖱️ Right Click + Drag: Pan camera</li>
            <li>🖱️ Scroll: Zoom in/out</li>
            <li>👆 Double Click: Reset camera</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default VirtualMannequin;