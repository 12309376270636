import React from 'react';
import './CSS/ReturnPolicy.css';
import calendar_icon from './Assets/calendar.gif';
import check_icon from './Assets/check.png';
import email_icon from './Assets/email.gif';
import login_icon from './Assets/Login.png';
import nf_icon from './Assets/nonRefundable.png';
import print_icon from './Assets/print.svg';
import refund_icon from './Assets/refund.gif';
import select_icon from './Assets/select.png';
import support_icon from './Assets/support.gif';

const ReturnPolicy = () => {
  return (
    <div>
      <section className="policy-section eligibility">
        <h2>Eligibility for Returns</h2>
        <ul>
          <li><img src={check_icon} alt="Check Icon" /> Items must be in original condition, unused, and with all tags attached.</li>
          <li><img src={calendar_icon} alt="Calendar Icon" /> Returns must be made within 30 days of delivery.</li>
        </ul>
      </section>

      <section className="policy-section initiate">
        <h2>How to Initiate a Return</h2>
        <ul>
          <li><img src={login_icon} alt="Login Icon" /> Log into your account and navigate to the "Orders" section.</li>
          <li><img src={select_icon} alt="Select Icon" /> Select the item(s) you wish to return and follow the prompts.</li>
          <li><img src={print_icon} alt="Print Icon" /> Print the return label and attach it to your package.</li>
        </ul>
      </section>

      <section className="policy-section refunds">
        <h2>Refunds and Processing</h2>
        <ul>
          <li><img src={refund_icon} alt="Refund Icon" /> Refunds will be processed to the original payment method within 7-10 business days of receiving the returned item(s).</li>
          <li><img src={nf_icon} alt="nonRefundable Icon" /> Please note that shipping costs are non-refundable.</li>
        </ul>
      </section>

      <section className="policy-section support">
        <h2>Need Help?</h2>
        <ul>
          <li><img src={support_icon} alt="Support Icon" /> Contact our support team at&nbsp;<a href="mailto:topcarefashion2024term4@gmail.com">support@topcarefashion.com</a>&nbsp; for any assistance.</li>
          <li><img src={email_icon} alt="Email Icon" /> <a href="mailto:topcarefashion2024term4@gmail.com" className="cta-button">Email Support</a></li>
        </ul>
      </section>
    </div>
  );
}

export default ReturnPolicy;
