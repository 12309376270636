import React, { useState } from 'react';
import './CSS/LoginSignUp.css';

export const LoginSignUp = () => {
  const [state, setState] = useState("Login");

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: "",
    shippingAddress: "",
    gender: "", // New gender field
    birthday: "" // New birthday field
  });

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Validate form fields
  const validateForm = () => {
    const { username, password, email, shippingAddress, gender, birthday } = formData;

    if (state === "Sign Up") {
      // If Sign Up, ensure all fields are filled
      return username && password && email && shippingAddress && gender && birthday;
    } else {
      // If Login, ensure email and password are filled
      return email && password;
    }
  };

  // LOGIN function
  const login = async () => {
    if (!validateForm()) {
      alert("Please fill in all required fields.");
      return;
    }

    console.log("Login function", formData);

    let responseData;
    // call login API
    await fetch('https://api.topcare.fashion/api/auth/login', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    }).then((response) => response.json()).then((data) => responseData = data);

    if (responseData.success) {
      // Add JWT to localstorage
      localStorage.setItem('auth-token', responseData.token);
      // Redirect to Home page
      window.location.replace("/");
    } else {
      alert(responseData.errors);
    }
  };

  // SIGNUP function
  const signup = async () => {
    if (!validateForm()) {
      alert("Please fill in all required fields.");
      return;
    }

    console.log("SignUp function", formData);

    let responseData;
    // call signup API
    await fetch('https://api.topcare.fashion/api/auth/signup', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    }).then((response) => response.json()).then((data) => responseData = data);

    if (responseData.success) {
      // Add JWT to localstorage
      localStorage.setItem('auth-token', responseData.token);
      alert("Account Successfully Created, Logging in");
      // Redirect to Home page
      window.location.replace("/");
    } else {
      alert(responseData.errors);
    }
  };

  return (
    <div className='loginsignup'>
      <div className="loginsignup-container">
        <h1>{state}</h1>

        <div className="loginsignup-fields">
          {state === "Sign Up" ? (
            <>
              <input
                className="full-width"
                name='username'
                value={formData.username}
                onChange={changeHandler}
                type="text"
                placeholder='Your Name'
                required // Mark as required
              />
              <input
                className="full-width"
                name='shippingAddress'
                value={formData.shippingAddress}
                onChange={changeHandler}
                type="text"
                placeholder='Shipping Address'
                required // Mark as required
              />
              <div className="small-fields">
                <select
                  name='gender'
                  value={formData.gender}
                  onChange={changeHandler}
                  required // Mark as required
                >
                  <option value="">Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                <input
                  name='birthday'
                  value={formData.birthday}
                  onChange={changeHandler}
                  type="date"
                  required // Mark as required
                />
              </div>
            </>
          ) : null}

          <input
            className="full-width"
            name='email'
            value={formData.email}
            onChange={changeHandler}
            type="email"
            placeholder='Email Address'
            required // Mark as required
          />
          <input
            className="full-width"
            name='password'
            value={formData.password}
            onChange={changeHandler}
            type="password"
            placeholder='Password'
            required // Mark as required
          />
        </div>

        <button onClick={() => { state === "Login" ? login() : signup() }}>Continue</button>

        <div className="loginsignup-change-mode">
          {state === "Sign Up" ? (
            <p className="loginsingup-login">Already have an account? <span onClick={() => { setState("Login") }}>Login Here</span></p>
          ) : (
            <p className="loginsingup-login">Don't have an account? <span onClick={() => { setState("Sign Up") }}>Create Account</span></p>
          )}
        </div>
      </div>
    </div>
  );
};
