import React from 'react'
import Hero  from '../Components/Hero/Hero'
import { Popular } from '../Components/Popular/Popular'
import Recommendations   from '../Components/Recommendations/Recommendations'
import Popup from '../Components/Popup/Popup'

export const Shop = () => {

  return (
    <div>
        <Popup/>
        <Hero/>
        <Recommendations/>
        <Popular/>
    </div>
  )
}
