// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-container {
    position: relative; /* Changed from absolute to relative */
    top: 0; /* Reset the top value */
    left: 0; /* Reset the left value */
    margin: 50px auto; /* Add margin for spacing and center alignment */
    width: 1560px; /* Fixed width for full-size image display */
    height: 335px; /* Fixed height to match the image dimensions */
    overflow: hidden; /* Hide extra images */
    background-color: #faefe9; /* Loyalty page color theme */
    border: 3px solid #FF4D39; /* Border matching loyalty theme */
    z-index: 1; /* Ensure it doesn't overlap other elements */
}

.carousel {
    display: flex;
    transition: transform 1s ease-in-out; /* Smooth slide animation */
    width: calc(1560px * 5);
}

.carousel-item {
    flex: 0 0 1560px; /* Ensure each item takes up exactly 1200px of width */
    height: 335px; /* Set height to match the container */
}

.carousel img {
    width: 100%;
    height: 100%;
    object-fit: fill; /* Ensure the full image is displayed */
    border-radius: 10px;
}


/* Hide carousel on mobile view */
@media (max-width: 768px) {
    .carousel-container {
        display: none; /* Hide the carousel container on mobile */
    }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Hero/Hero.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB,EAAE,sCAAsC;IAC1D,MAAM,EAAE,wBAAwB;IAChC,OAAO,EAAE,yBAAyB;IAClC,iBAAiB,EAAE,gDAAgD;IACnE,aAAa,EAAE,4CAA4C;IAC3D,aAAa,EAAE,+CAA+C;IAC9D,gBAAgB,EAAE,sBAAsB;IACxC,yBAAyB,EAAE,6BAA6B;IACxD,yBAAyB,EAAE,kCAAkC;IAC7D,UAAU,EAAE,6CAA6C;AAC7D;;AAEA;IACI,aAAa;IACb,oCAAoC,EAAE,2BAA2B;IACjE,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB,EAAE,sDAAsD;IACxE,aAAa,EAAE,sCAAsC;AACzD;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB,EAAE,uCAAuC;IACzD,mBAAmB;AACvB;;;AAGA,iCAAiC;AACjC;IACI;QACI,aAAa,EAAE,0CAA0C;IAC7D;AACJ","sourcesContent":[".carousel-container {\n    position: relative; /* Changed from absolute to relative */\n    top: 0; /* Reset the top value */\n    left: 0; /* Reset the left value */\n    margin: 50px auto; /* Add margin for spacing and center alignment */\n    width: 1560px; /* Fixed width for full-size image display */\n    height: 335px; /* Fixed height to match the image dimensions */\n    overflow: hidden; /* Hide extra images */\n    background-color: #faefe9; /* Loyalty page color theme */\n    border: 3px solid #FF4D39; /* Border matching loyalty theme */\n    z-index: 1; /* Ensure it doesn't overlap other elements */\n}\n\n.carousel {\n    display: flex;\n    transition: transform 1s ease-in-out; /* Smooth slide animation */\n    width: calc(1560px * 5);\n}\n\n.carousel-item {\n    flex: 0 0 1560px; /* Ensure each item takes up exactly 1200px of width */\n    height: 335px; /* Set height to match the container */\n}\n\n.carousel img {\n    width: 100%;\n    height: 100%;\n    object-fit: fill; /* Ensure the full image is displayed */\n    border-radius: 10px;\n}\n\n\n/* Hide carousel on mobile view */\n@media (max-width: 768px) {\n    .carousel-container {\n        display: none; /* Hide the carousel container on mobile */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
