import React, { useState, useEffect, useRef, useContext } from 'react';
import VirtualMannequin from '../Components/VirtualMannequin/VirtualMannequin';
import './CSS/MixMatch.css';
import { ShopContext } from '../Context/ShopContext';

const GenderSelector = ({ selectedGender, onGenderChange }) => {
  return (
    <div className="gender-selector">
      <button
        className={`gender-btn ${selectedGender === 'men' ? 'active' : ''}`}
        onClick={() => onGenderChange('men')}
      >
        Men's Clothing
      </button>
      <button
        className={`gender-btn ${selectedGender === 'women' ? 'active' : ''}`}
        onClick={() => onGenderChange('women')}
      >
        Women's Clothing
      </button>
    </div>
  );
};

const ScrollableSection = ({ title, items, selectedItem, onItemSelect }) => {
  const scrollRef = useRef(null);

  const scroll = (direction) => {
    const container = scrollRef.current;
    if (container) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <div className="selection-section">
      <h2 className="section-header">{title}</h2>
      <div className="scroll-container">
        <div className="models-grid">
          {items.map((item, index) => (
            <div
              key={item.id}
              className={`model-card ${selectedItem?.id === item.id ? 'selected' : ''}`}
              onClick={() => onItemSelect(item)}
            >
              <img src={item.image} alt={item.name} />
              <div className="model-info">
                <h3>
                  {item.name}
                  {index < 8 && <span className="new-tag">NEW</span>}
                </h3>
                <p>${item.new_price}</p>
              </div>
            </div>
          ))}
          {items.length === 0 && (
            <div className="no-items">No {title.toLowerCase()} available with 3D models</div>
          )}
        </div>
      </div>
    </div>
  );
};


const MixMatch = () => {
  const { addToCart } = useContext(ShopContext); // Get addToCart from ShopContext
  const [selectedGender, setSelectedGender] = useState('men');
  const [selectedTop, setSelectedTop] = useState(null);
  const [selectedBottom, setSelectedBottom] = useState(null);
  const [selectedFootwear, setSelectedFootwear] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await fetch('https://api.topcare.fashion/api/products/products', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'auth-token': localStorage.getItem('auth-token'),
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }

      const data = await response.json();
      const sortedProducts = (data || []).sort((a, b) => new Date(b.date) - new Date(a.date));
      setProducts(sortedProducts);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching products:', err);
      setError('Failed to load products');
      setLoading(false);
    }
  };

  useEffect(() => {
    setSelectedTop(null);
    setSelectedBottom(null);
    setSelectedFootwear(null);
  }, [selectedGender]);

  const getFilteredItems = (type) => {
    return products.filter(
      (item) => item.type === type && item.category === selectedGender && item.has3DModel
    );
  };

  const tops = getFilteredItems('top');
  const bottoms = getFilteredItems('bottom');
  const footwear = getFilteredItems('footwear');

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="mix-match-container">
      <GenderSelector selectedGender={selectedGender} onGenderChange={setSelectedGender} />

      <div className="models-selection">
        <ScrollableSection title="Tops" items={tops} selectedItem={selectedTop} onItemSelect={setSelectedTop} />
        <ScrollableSection title="Bottoms" items={bottoms} selectedItem={selectedBottom} onItemSelect={setSelectedBottom} />
        <ScrollableSection title="Footwear" items={footwear} selectedItem={selectedFootwear} onItemSelect={setSelectedFootwear} />
      </div>

      <div className="model-viewer-section">
        <h2>Preview Your Outfit</h2>
        {(selectedTop || selectedBottom || selectedFootwear) ? (
          <VirtualMannequin
            selectedTop={selectedTop}
            selectedBottom={selectedBottom}
            selectedFootwear={selectedFootwear}
          />
        ) : (
          <div className="no-model-selected">Please select items to preview your outfit</div>
        )}

        {(selectedTop || selectedBottom || selectedFootwear) && (
          <div className="selected-items-summary">
            <h3>Selected Items:</h3>
            <div className="selected-items-grid">
              {selectedTop && (
                <div className="selected-item">
                  <h4>Top: {selectedTop.name}</h4>
                  <p>${selectedTop.new_price}</p>
                  <button onClick={() => addToCart(selectedTop.id)}>Add to Cart</button>
                </div>
              )}
              {selectedBottom && (
                <div className="selected-item">
                  <h4>Bottom: {selectedBottom.name}</h4>
                  <p>${selectedBottom.new_price}</p>
                  <button onClick={() => addToCart(selectedBottom.id)}>Add to Cart</button>
                </div>
              )}
              {selectedFootwear && (
                <div className="selected-item">
                  <h4>Footwear: {selectedFootwear.name}</h4>
                  <p>${selectedFootwear.new_price}</p>
                  <button onClick={() => addToCart(selectedFootwear.id)}>Add to Cart</button>
                </div>
              )}
              <div className="total-price">
                <h4>Total:</h4>
                <p>${((selectedTop?.new_price || 0) + (selectedBottom?.new_price || 0) + (selectedFootwear?.new_price || 0)).toFixed(2)}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MixMatch;
