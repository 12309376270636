import React, { useContext, useState } from 'react';
import './CSS/ShopCategory.css';
import { ShopContext } from '../Context/ShopContext';
import dropdown_icon from '../Components/Assets/dropdown_icon.png';
import { Item } from '../Components/Item/Item';

export const ShopCategory = (props) => {
  // Bring in shop items data
  const { all_product } = useContext(ShopContext);

  // State for dropdown visibility, selected sort type, and date sorting
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dateDropdownOpen, setDateDropdownOpen] = useState(false); // New state for date dropdown
  const [sortType, setSortType] = useState('all');
  const [sortOrder, setSortOrder] = useState('latest'); // New state for sorting by date
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(500); // Set a default max price based on your product range

  // Function to handle sorting by product type
  const handleSort = (type) => {
    setSortType(type);
    setDropdownOpen(false);
  };

  // Function to handle sorting by date
  const handleDateSort = (order) => {
    setSortOrder(order);
    setDateDropdownOpen(false);
  };

  // Filter and sort products based on category, selected type, and date
  const filteredProducts = all_product
    .filter(item => {
      const withinCategory = props.category === item.category;
      const matchesType = sortType === 'all' || item.type === sortType;
      const withinPriceRange = item.new_price >= minPrice && item.new_price <= maxPrice;

      return withinCategory && matchesType && withinPriceRange;
    })
    .sort((a, b) => {
      if (sortOrder === 'latest') {
        return new Date(b.date) - new Date(a.date); // Latest items first
      } else {
        return new Date(a.date) - new Date(b.date); // Oldest items first
      }
    });

  return (
    <div className='shop-category'>
      <img className='shopcategory-banner' src={props.banner} alt="" />

      <div className="shopcategory-indexSort">
        <div className="shopcategory-sort" onClick={() => setDropdownOpen(!dropdownOpen)}>
          Sort by <img src={dropdown_icon} alt="" />
        </div>

        {/* Dropdown for sorting by product type */}
        {dropdownOpen && (
          <div className="shopcategory-dropdown">
            <div onClick={() => handleSort('all')}>All</div>
            <div onClick={() => handleSort('top')}>Top</div>
            <div onClick={() => handleSort('bottom')}>Bottom</div>
            <div onClick={() => handleSort('footwear')}>Footwear</div>
            <div onClick={() => handleSort('accessory')}>Accessory</div>
          </div>
        )}

        {/* New dropdown for sorting by date */}
        <div className="shopcategory-date-sort" onClick={() => setDateDropdownOpen(!dateDropdownOpen)}>
          Date Sort <img src={dropdown_icon} alt="" />
        </div>

        {dateDropdownOpen && (
          <div className="shopcategory-dropdown-2">
            <div onClick={() => handleDateSort('latest')}>Latest</div>
            <div onClick={() => handleDateSort('oldest')}>Oldest</div>
          </div>
        )}

        <div className="price-range-filter">
          <label className="price-range-label">Price Range: ${minPrice} - ${maxPrice}</label>
          <div className="slider-container">
            <div className="sliders">
              <input
                type="range"
                min="0"
                max="250" // Adjust this max value according to your product price range
                value={minPrice}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (value > maxPrice) {
                    setMaxPrice(value); // Ensure max is always greater than min
                  }
                  setMinPrice(value);
                }}
                className="slider"
              />
              <input
                type="range"
                min="250"
                max="500" // Adjust this max value according to your product price range
                value={maxPrice}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (value < minPrice) {
                    setMinPrice(value); // Ensure min is always less than max
                  }
                  setMaxPrice(value);
                }}
                className="slider"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Display items according to category, sort type, and date */}
      <div className="shopcategory-products">
        {filteredProducts.map((item, i) => (
          <Item key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} amountSold={item.amountSold} />
        ))}
      </div>
    </div>
  );
};
