import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ShopContext } from "../Context/ShopContext";
import './CSS/CheckOut.css';
import bank_icon from './Assets/bank_icon.svg';
import credit_icon from './Assets/credit_icon.svg';
import paypal_icon from './Assets/paypal_icon.svg';

const CheckOut = () => {
    const { clearCart } = useContext(ShopContext);

    const [shippingAddress, setShippingAddress] = useState('');
    const [selfCollection, setSelfCollection] = useState(false);  // Self-collection state
    const [collectionPoint, setCollectionPoint] = useState('');  // Selected collection point
    const [collectionPoints, setCollectionPoints] = useState([]);  // List of available collection points

    const location = useLocation();
    const navigate = useNavigate();

    const { amount, cartProducts, pointsDeducted } = location.state || { amount: 0, cartProducts: [], pointsDeducted: 0 };
    const [finalAmount, setFinalAmount] = useState(amount);
    const [paymentMethod, setPaymentMethod] = useState('creditCard');

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const res = await fetch('https://api.topcare.fashion/api/user/profile', {
                    headers: { 'auth-token': localStorage.getItem('auth-token') },
                });
                const data = await res.json();
                setShippingAddress(data.shippingAddress || '');
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        fetchUserData();
    }, []);

    useEffect(() => {
        setFinalAmount(amount);
    }, [amount]);

    // Fetch available collection points 
    useEffect(() => {
        setCollectionPoints(['Woodlands Industrial Park E5', 'Kaki Bukit Industrial Estate', 'Tanjong Pagar District Park', 'Pioneer Industrial Estate']);
    }, []);

    const handleConfirmOrder = () => {
        if (!selfCollection && !shippingAddress.trim()) {
            alert("Please enter your shipping address.");
            return;
        }
    
        if (selfCollection && !collectionPoint) {
            alert("Please select a collection point for self-collection.");
            return;
        }
    
        const orderData = cartProducts.reduce((acc, product) => {
            if (product.quantity > 0) acc[product.id] = product.quantity;
            return acc;
        }, {});
    
        fetch('https://api.topcare.fashion/api/orders/checkout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'auth-token': `${localStorage.getItem('auth-token')}`,
            },
            body: JSON.stringify({
                cartItems: orderData,
                paymentMethod,
                shippingAddress: selfCollection ? '' : shippingAddress,  // Clear address if self-collection
                collectionPoint: selfCollection ? collectionPoint : '',  // Set collection point if self-collection
                pointsDeducted,
            }),
        })
        .then((response) => response.json())
        .then((data) => {
            if (data.message) {
                alert(`Order successfully placed! You earned ${data.pointsEarned} points.`);
                clearCart();
                navigate("/");
            } else {
                alert("One or more items in your cart may be out of stock. Please review your cart.");
            }
        })
        .catch((error) => {
            console.error("Error processing order:", error);
            alert("Error processing order. Check the console for details.");
        });
    };
    

    return (
        <div className="checkout-container">
            <h2 className="checkout-header">Checkout</h2>
            <div className="cart-summary">
                <h3>Your Cart:</h3>
                <ul>
                    {cartProducts.map((product) => (
                        <li key={product.id}>
                            <img src={product.image} alt={product.name} className="product-image" />
                            {product.name} <br /> Quantity: {product.quantity} <br /> Price: ${product.new_price}
                        </li>
                    ))}
                </ul>
            </div>

            {/* Shipping Address Input (only show if self-collection is not selected) */}
            {!selfCollection && (
                <div className="shipping-address">
                    <h4>Shipping Address:</h4>
                    <textarea
                        rows="4"
                        cols="50"
                        placeholder="Enter your shipping address"
                        value={shippingAddress}
                        onChange={(e) => setShippingAddress(e.target.value)}
                    />
                </div>
            )}
            
            {/* Self-collection option */}
            <div className="self-collection-option">
                <h4>Opt for Self Collection: 
                    <label>
                        <input
                            type="checkbox"
                            checked={selfCollection}
                            onChange={(e) => setSelfCollection(e.target.checked)}
                        />
                    </label>
                </h4>
            </div>

            {/* Collection Point Dropdown (only show if self-collection is selected) */}
            {selfCollection && (
                <div className="collection-point-dropdown">
                    <h4>Select Collection Point: 
                        <select value={collectionPoint} onChange={(e) => setCollectionPoint(e.target.value)}>
                            <option value="">Select a collection point</option>
                            {collectionPoints.map((point) => (
                                <option key={point} value={point}>{point}</option>
                            ))}
                        </select>
                    </h4>   
                </div>
            )}

            <div className="payment-method">
                <h4>Select Payment Method:</h4>
                <div className="payment-options">
                    <label className="payment-option">
                        <img src={credit_icon} alt="Credit Card" />
                        <input
                            type="radio"
                            name="paymentMethod"
                            value="creditCard"
                            checked={paymentMethod === 'creditCard'}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                        Credit Card
                    </label>
                    <label className="payment-option">
                        <img src={paypal_icon} alt="PayPal" />
                        <input
                            type="radio"
                            name="paymentMethod"
                            value="paypal"
                            checked={paymentMethod === 'paypal'}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                        PayPal
                    </label>
                    <label className="payment-option">
                        <img src={bank_icon} alt="Bank Transfer" />
                        <input
                            type="radio"
                            name="paymentMethod"
                            value="bankTransfer"
                            checked={paymentMethod === 'bankTransfer'}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                        Bank Transfer
                    </label>
                </div>

                <div className="checkout-summary">
                    <h4>Total Amount: ${finalAmount}</h4>
                </div>

                <button className="confirm-order-btn" onClick={handleConfirmOrder}>Confirm</button>
            </div>
        </div>
    );
};

export default CheckOut;
