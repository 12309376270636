import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './CSS/Profile.css';

const Profile = () => {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    gender: '',
    birthday: '',
    shippingAddress: '',
    lifeTimePoints: 0,
    points: 0,
    checkInStreak: 0,
  });

  const [tier, setTier] = useState('Bronze');
  const [pointsToNextTier, setPointsToNextTier] = useState(100);

  useEffect(() => {
    const fetchUserData = async () => {
      await fetch('https://api.topcare.fashion/api/user/profile', {
        headers: {
          'auth-token': localStorage.getItem('auth-token'),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setUserData(data);
          determineTier(data.lifeTimePoints);
        });
    };

    fetchUserData();
  }, []);

  const determineTier = (points) => {
    if (points >= 1000) {
      setTier('Platinum');
      setPointsToNextTier(0);
    } else if (points >= 500) {
      setTier('Gold');
      setPointsToNextTier(1000 - points);
    } else if (points >= 100) {
      setTier('Silver');
      setPointsToNextTier(500 - points);
    } else {
      setTier('Bronze');
      setPointsToNextTier(100 - points);
    }
  };

  const handleCheckIn = async () => {
    const response = await fetch('https://api.topcare.fashion/api/user/checkin', {
      method: 'POST',
      headers: {
        'auth-token': localStorage.getItem('auth-token'),
      },
    });
    const data = await response.json();
    if (response.ok) {
      setUserData((prevData) => ({
        ...prevData,
        lifeTimePoints: data.totalPoints,
      }));
      determineTier(data.totalPoints);
      alert(`Check-in successful! You earned ${data.pointsAwarded} points.`);
    } else {
      alert(data.message || 'Check-in failed');
    }
  };

  // Progress Bar Component
 const ProgressBar = ({ progress, max }) => {
  const percentage = (progress / max) * 100;
  
  const progressBarStyle = {
    width: `${percentage}%`,
    height: '100%',
    backgroundColor: '#ff7c6d', // Customize the color as needed
    borderRadius: '4px',
    transition: 'width 0.3s ease',
  };

  const containerStyle = {
    width: '100%',
    backgroundColor: '#f3f3f3', // Light gray background
    borderRadius: '4px',
    height: '20px',
    margin: '10px 0',
  };

  return (
    <div style={containerStyle}>
      <div style={progressBarStyle}></div>
    </div>
  );
};

  return (
    <div className='profile'>
      <div className='profile-details'>
        <h2>Profile Details</h2>
        <p>Name: {userData.name}</p>
        <p>Email: {userData.email}</p>
        <p>Gender: {userData.gender}</p>
        
        <p>Birthday: {new Date(userData.birthday).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit'
        })}
        </p>

        <p>Shipping Address: {userData.shippingAddress}</p>
        <div className="profile-options">
          <Link to='/editprofile'>
            <button className='edit-profile-btn'>Edit Profile</button>
          </Link>

          <Link to='/changepassword'>
            <button className='change-password-btn'>Change Password</button>
          </Link>
        </div>
        <br />
        <hr />
        <br />
        <p>Lifetime Points: {userData.lifeTimePoints}</p>
        <p>Usable Points: {userData.points}</p>
        <p>
          Current Tier: <Link to='/brandloyalty' title="Find out more about our different tiers available">{tier}</Link>
        </p>

        
        {/* Display Progress Bar */}
        {tier !== 'Platinum' && (
          <ProgressBar
            progress={userData.lifeTimePoints}
            max={tier === 'Gold' ? 1000 : tier === 'Silver' ? 500 : 100}
          />
        )}

        {tier === 'Platinum' ? (
          <p>Congratulations! You've achieved the maximum tier.</p>
        ) : (
          <p>{pointsToNextTier} points to next tier</p>
        )}

        <p>Login Streak: {userData.checkInStreak}</p>
        <button onClick={handleCheckIn} className="check-in-btn">
          Daily Check-In
        </button>
        <br />

      </div>
    </div>
  );
};

export default Profile;
