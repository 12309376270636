import React, { useEffect, useState, useContext } from 'react';
import { ShopContext } from '../../../Context/ShopContext'; // Adjust the path as needed
import './Orders.css';

const Orders = () => {
  const { all_product } = useContext(ShopContext);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState({
    _id: "",
  });

  // Fetch user data and orders
  useEffect(() => {
    const fetchUserData = async () => {
      await fetch('https://api.topcare.fashion/api/user/profile', {
        headers: {
          'auth-token': localStorage.getItem('auth-token'),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setUserData(data);
        });
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(`https://api.topcare.fashion/api/orders/seller/${userData._id}`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch orders');
        }

        const data = await response.json();
        setOrders(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    if (userData._id) {
      fetchOrders();
    }
  }, [userData._id]);

  // Function to get product details by productID
  const getProductDetails = (productID) => {
    return all_product.find(product => product._id === productID) || {};
  };

  const handleStatusChange = async (orderID, newStatus) => {
    try {
      const response = await fetch(`https://api.topcare.fashion/api/orders/update/${orderID}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': localStorage.getItem('auth-token'),
        },
        body: JSON.stringify({ status: newStatus }),
      });

      if (!response.ok) {
        throw new Error('Failed to update status');
      }

      // Fetch updated orders
      const updatedOrdersResponse = await fetch(`https://api.topcare.fashion/api/orders/seller/${userData._id}`);
      const updatedOrders = await updatedOrdersResponse.json();
      setOrders(updatedOrders);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleAcknowledgeReturn = async (orderID) => {
    await handleStatusChange(orderID, "returned");
  };

  if (loading) return <p>Loading orders...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="orders-container">
      <h1>List of Orders</h1>
      <div className="orders-list">
        {orders.length === 0 ? (
          <p>No orders yet.</p>
        ) : (
          orders.map(order => {
            const product = getProductDetails(order.productID);
            return (
              <div key={order._id} className="order-item">
                <div className="order-details">
                  <p><strong>Product Name:</strong> {product.name || 'Unknown Product'}</p>
                  <p><strong>Product ID:</strong> {order.productID}</p>
                </div>
      
                <img src={product.image || 'placeholder-image-url'} alt={product.name || 'Product Image'} className="product-image" />
                
                <div className="order-details">
                  <p><strong>Buyer ID:</strong> {order.buyerID}</p>
                  <p><strong>Quantity:</strong> {order.quantity}</p>
                  <p><strong>Billing Amount:</strong> ${order.billingAmount}</p>
                  <div className="order-status">
                    <strong>Status:</strong>
                    <select
                      value={order.status}
                      onChange={(e) => handleStatusChange(order._id, e.target.value)}
                    >
                      <option value="pending">Pending</option>
                      <option value="shipped">Shipped</option>
                      <option value="delivered">Delivered</option>
                      <option value="canceled">Canceled</option>
                      <option value="return requested">Return Requested</option>
                      <option value="returned">Returned</option>
                      <option value="received">Received</option>
                    </select>
                    {order.status === "return requested" && (
                      <button onClick={() => handleAcknowledgeReturn(order._id)}>
                        Acknowledge Return
                      </button>
                    )}
                  </div>
                  <p><strong>Payment Method: </strong> {order.paymentMethod}</p>

                  <p>
                    <strong>{order.shippingAddress ? 'Shipping Address' : 'Collection Point'}: </strong> 
                    {order.shippingAddress || order.collectionPoint}
                  </p>

                  <p><strong>Order Date: </strong> {new Date(order.orderDate).toLocaleDateString()}</p>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default Orders;
