import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Item } from '../Components/Item/Item'; 
import './CSS/SearchResults.css';

const SearchResults = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('q');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`https://api.topcare.fashion/api/products/search?query=${encodeURIComponent(query)}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        const data = await response.json();
        setProducts(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching search results:', error);
        setError('Failed to fetch products');
        setLoading(false);
      }
    };

    if (query) {
      fetchProducts();
    }
  }, [query]);

  return (
    <div className="search-results">
      <h2>Search Results for "{query}"</h2>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      
      <div className="product-list">
        {products.length > 0 ? (
          products.map((product) => (
            <Item
              key={product.id}
              id={product.id}
              image={product.image}
              name={product.name}
              new_price={product.new_price}
              amountSold={product.amountSold} // Pass amountSold to Item
            />
          ))
        ) : (
          <p>No products found</p>
        )}
      </div>
    </div>
  );
};

export default SearchResults;
