// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-product {
    width: 100%;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .listproduct-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .listproduct-table th,
  .listproduct-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
    vertical-align: middle;
  }
  
  .listproduct-product-icon {
    width: 80px;
    object-fit: cover;
  }
  
  .editable-field,
  select {
    width: 100px; /* Small width for fields */
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .editable-textarea {
    width: 90%; /* Full width for textarea */
    min-height: 90px;
    resize: vertical;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .listproduct-remove-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`, "",{"version":3,"sources":["webpack://./src/Seller/Components/ListProduct/ListProduct.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,gBAAgB;EAClB;;EAEA;;IAEE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,WAAW;IACX,iBAAiB;EACnB;;EAEA;;IAEE,YAAY,EAAE,2BAA2B;IACzC,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,UAAU,EAAE,4BAA4B;IACxC,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,eAAe;EACjB","sourcesContent":[".list-product {\n    width: 100%;\n    padding: 20px;\n    font-family: Arial, sans-serif;\n  }\n  \n  .listproduct-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin-top: 20px;\n  }\n  \n  .listproduct-table th,\n  .listproduct-table td {\n    padding: 10px;\n    border: 1px solid #ddd;\n    text-align: center;\n    vertical-align: middle;\n  }\n  \n  .listproduct-product-icon {\n    width: 80px;\n    object-fit: cover;\n  }\n  \n  .editable-field,\n  select {\n    width: 100px; /* Small width for fields */\n    padding: 5px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    box-sizing: border-box;\n  }\n  \n  .editable-textarea {\n    width: 90%; /* Full width for textarea */\n    min-height: 90px;\n    resize: vertical;\n    padding: 5px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .listproduct-remove-icon {\n    width: 20px;\n    height: 20px;\n    cursor: pointer;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
