// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 60px;
    box-shadow: 0 1px 3px -2px #000;
    margin-bottom: 1px;
    background: #d9d9d9;
}

.navbar p {
    font-size: 20px;
    font-weight: 600;
}

.nav-logo {
    width: 70px;
}

.marketplace-button {
    margin-right: 20px;
    padding: 10px 20px;
    font-size: 18px;
    color: #fff;
    background-color: #6c757d;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.nav-profile {
    width: 50px;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    width: 120px;
    top: 100%;
    right: 0;
    background-color: #6c757d;
    border: 1px solid black;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .dropdown-menu a {
    margin: 5px 0;
    color: white;
    text-decoration: none;
  }
  
  .dropdown-menu a:hover {
    text-decoration: underline;
  }`, "",{"version":3,"sources":["webpack://./src/Seller/Components/SellerNav/Navbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,kBAAkB;IAClB,+BAA+B;IAC/B,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;AAC1C;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,SAAS;IACT,QAAQ;IACR,yBAAyB;IACzB,uBAAuB;IACvB,aAAa;IACb,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,YAAY;IACZ,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".navbar {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 15px 60px;\n    box-shadow: 0 1px 3px -2px #000;\n    margin-bottom: 1px;\n    background: #d9d9d9;\n}\n\n.navbar p {\n    font-size: 20px;\n    font-weight: 600;\n}\n\n.nav-logo {\n    width: 70px;\n}\n\n.marketplace-button {\n    margin-right: 20px;\n    padding: 10px 20px;\n    font-size: 18px;\n    color: #fff;\n    background-color: #6c757d;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n}\n\n.nav-profile {\n    width: 50px;\n    cursor: pointer;\n}\n\n.dropdown-menu {\n    position: absolute;\n    width: 120px;\n    top: 100%;\n    right: 0;\n    background-color: #6c757d;\n    border: 1px solid black;\n    padding: 10px;\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .dropdown-menu a {\n    margin: 5px 0;\n    color: white;\n    text-decoration: none;\n  }\n  \n  .dropdown-menu a:hover {\n    text-decoration: underline;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
