import React from 'react';
import './CSS/BrandLoyalty.css'; 
import trading_gif from '../Components/Assets/gifs/trading.gif'
import dollar_gif from '../Components/Assets/gifs/dollar.gif'
import free_delivery_gif from '../Components/Assets/gifs/free-delivery.gif'
import gift_gif from '../Components/Assets/gifs/gift.gif'

const BrandLoyalty = () => {
  return (
    <>
      <section className="tier bronze">
        <h2>Bronze Tier</h2>
        <p><strong>0 - 99 points</strong></p>
        <ul>
          <li><img src={trading_gif} alt="Earning Points" /> Earn 1 point for every $5 spent</li>
          <li><img src={dollar_gif} alt="Dollar Rebates" /> $1 rebate for every 20 points earned</li>
        </ul>
      </section>

      <section className="tier silver">
        <h2>Silver Tier</h2>
        <p><strong>100 - 499 points</strong></p>
        <ul>
          <li><img src={trading_gif} alt="Earning Points" /> Earn 1.25 points for every $5 spent</li>
          <li><img src={dollar_gif} alt="Dollar Rebates" /> $1 rebate for every 20 points earned</li>
        </ul>
      </section>

      <section className="tier gold">
        <h2>Gold Tier</h2>
        <p><strong>500 - 999 points</strong></p>
        <ul>
          <li><img src={trading_gif} alt="Earning Points" /> Earn 1.5 points for every $5 spent</li>
          <li><img src={free_delivery_gif} alt="Free Delivery" /> Free shipping on every purchase</li>
          <li><img src={gift_gif} alt="Birthday Discount" /> Birthday discount</li>
          <li><img src={dollar_gif} alt="Dollar Rebates" /> $1 rebate for every 18 points earned</li>
        </ul>
      </section>

      <section className="tier platinum">
        <h2>Platinum Tier</h2>
        <p><strong>1,000+ points</strong></p>
        <ul>
          <li><img src={trading_gif} alt="Earning Points" /> Earn 2 points for every $5 spent</li>
          <li><img src={free_delivery_gif} alt="Free Delivery" /> Free shipping on every purchase</li>
          <li><img src={gift_gif} alt="Birthday Discount" /> Birthday discount</li>
          <li><img src={dollar_gif} alt="Dollar Rebates" /> $1 rebate for every 15 points earned</li>
          <li><img src={gift_gif} alt="Priority Service" /> Priority service</li>
        </ul>
      </section>
    </>
  );
};

export default BrandLoyalty;
