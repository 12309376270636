import React, { useState, useEffect } from 'react';
import './Item.css';
import { Link } from 'react-router-dom';
import star_icon from "../Assets/star_icon.png";  // Full star icon
import star_dull_icon from "../Assets/star_dull_icon.png";  // Empty star icon

export const Item = (props) => {
  const [averageRating, setAverageRating] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);

  // Fetch reviews and calculate average rating
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch(`https://api.topcare.fashion/api/reviews/${props.id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch reviews");
        }
        const data = await response.json();

        // Handle the case where there are no reviews
        if (data.length === 0) {
          setAverageRating(0);
          setReviewCount(0);
        } else {
          // Calculate average rating
          const totalRating = data.reduce((sum, review) => sum + review.rating, 0);
          const averageRating = totalRating / data.length;

          // Update review count and average rating
          setAverageRating(averageRating);
          setReviewCount(data.length);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchReviews();
  }, [props.id]);

  // Function to render star icons based on rating
  const renderStars = () => {
    const filledStars = Math.floor(averageRating);
    const emptyStars = 5 - filledStars;

    return (
      <>
        {Array(filledStars).fill().map((_, i) => (
          <img key={i} src={star_icon} alt="Filled Star" className="star-icon" />
        ))}
        {Array(emptyStars).fill().map((_, i) => (
          <img key={i} src={star_dull_icon} alt="Dull Star" className="star-icon" />
        ))}
      </>
    );
  };

  return (
    <div className='item'>
      {/* Link to navigate to product details */}
      <Link to={`/product/${props.id}`}>
        <img onClick={() => window.scrollTo(0, 0)} src={props.image} alt={props.name} />
      </Link>

      <Link to={`/product/${props.id}`}>
        <p>{props.name}</p>
      </Link>

      {/* Display star rating */}
      <div className="item-rating">
        {renderStars()}
        <span> ({reviewCount} reviews)</span>
      </div>

      <div className="item-prices">
        <div className="item-prices-new">
          ${props.new_price}
        </div>
      </div>
      {props.amountSold} Sold
    </div>
  );
};
