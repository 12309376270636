// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orders-container {
    margin: 40px 150px;
  }
  
  .orders-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .order-item {
    border: 1px solid #d0d0d0;
    padding: 20px;
    border-radius: 8px;
  }
  
  .order-item p {
    margin: 5px 0;
  }
  
  .product-image {
    max-width: 100px; /* Adjust as needed */
    max-height: 100px; /* Adjust as needed */
    object-fit: cover;
    margin-top: 10px;
  }
  
  /* Ensure content starting from 'Status' onwards is on a new line */
  .order-item .order-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .order-status select {
    margin-left: 10px;
    width: -moz-fit-content;
    width: fit-content;
  }

  .order-status button {
    margin-left: 10px;
    padding: 5px 10px;
    font-size: 14px;
    color: #fff;
    background-color: #5cb85c;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .order-status button:hover {
    background-color: #4cae4c;
  }
  `, "",{"version":3,"sources":["webpack://./src/Seller/Components/Orders/Orders.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,gBAAgB,EAAE,qBAAqB;IACvC,iBAAiB,EAAE,qBAAqB;IACxC,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA,mEAAmE;EACnE;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,iBAAiB;IACjB,uBAAkB;IAAlB,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".orders-container {\n    margin: 40px 150px;\n  }\n  \n  .orders-list {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n  }\n  \n  .order-item {\n    border: 1px solid #d0d0d0;\n    padding: 20px;\n    border-radius: 8px;\n  }\n  \n  .order-item p {\n    margin: 5px 0;\n  }\n  \n  .product-image {\n    max-width: 100px; /* Adjust as needed */\n    max-height: 100px; /* Adjust as needed */\n    object-fit: cover;\n    margin-top: 10px;\n  }\n  \n  /* Ensure content starting from 'Status' onwards is on a new line */\n  .order-item .order-details {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n  }\n  \n  .order-status select {\n    margin-left: 10px;\n    width: fit-content;\n  }\n\n  .order-status button {\n    margin-left: 10px;\n    padding: 5px 10px;\n    font-size: 14px;\n    color: #fff;\n    background-color: #5cb85c;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .order-status button:hover {\n    background-color: #4cae4c;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
