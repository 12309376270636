// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change-password {
    width: 50%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 15px;
}

.change-password h1 {
    text-align: center;
    margin-bottom: 20px;
}

.change-password-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.save-btn {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: center;
}

.save-btn:hover {
    background-color: #5a6268;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/CSS/ChangePassword.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;IACxC,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;IACtC,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".change-password {\n    width: 50%;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: #f8f9fa;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    margin-top: 15px;\n}\n\n.change-password h1 {\n    text-align: center;\n    margin-bottom: 20px;\n}\n\n.change-password-form {\n    display: flex;\n    flex-direction: column;\n}\n\n.form-group {\n    margin-bottom: 15px;\n}\n\n.form-group label {\n    margin-bottom: 5px;\n    font-weight: bold;\n}\n\n.form-group input {\n    padding: 10px;\n    font-size: 16px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    width: 100%;\n}\n\n.save-btn {\n    padding: 10px 20px;\n    font-size: 16px;\n    background-color: #6c757d;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n    align-self: center;\n}\n\n.save-btn:hover {\n    background-color: #5a6268;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
