import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './SellerProfile.css';

export const SellerProfile = (props) => {
  const { product } = props;
  const [sellerName, setSellerName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSellerName = async () => {
      try {
        const response = await fetch(`https://api.topcare.fashion/api/user/${product.sellerID}`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch seller information');
        }

        const data = await response.json();
        setSellerName(data.name); // Update based on the field returned by the API
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchSellerName();
  }, [product.sellerID]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="seller-profile">
      <p>Seller: {sellerName}</p>

      <Link to={`/chatwithseller?sellerID=${product.sellerID}`}>
        <button className="chat-button">Chat With Seller</button>
      </Link>
    </div>
  );
}
