// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-profile {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 30px;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.edit-profile h1 {
    text-align: center;
    margin-bottom: 20px;
}

.edit-profile-form .form-group {
    margin-bottom: 15px;
}

.edit-profile-form .form-group label {
    display: block;
    margin-bottom: 5px;
}

.edit-profile-form .form-group input {
    width: 80%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.save-btn {
    width: 20%;
    padding: 10px;
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.save-btn:hover {
    background-color: #FF4D39 !important;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/CSS/EditProfile.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,gBAAgB;IAChB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,uCAAuC;AAC3C;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;AAC1C;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":[".edit-profile {\n    max-width: 500px;\n    margin: 0 auto;\n    margin-top: 30px;\n    padding: 20px;\n    background-color: #f7f7f7;\n    border-radius: 10px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.edit-profile h1 {\n    text-align: center;\n    margin-bottom: 20px;\n}\n\n.edit-profile-form .form-group {\n    margin-bottom: 15px;\n}\n\n.edit-profile-form .form-group label {\n    display: block;\n    margin-bottom: 5px;\n}\n\n.edit-profile-form .form-group input {\n    width: 80%;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n}\n\n.save-btn {\n    width: 20%;\n    padding: 10px;\n    background-color: #6c757d;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n}\n\n.save-btn:hover {\n    background-color: #FF4D39 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
