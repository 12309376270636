import React, { useEffect, useState, useContext } from 'react';
import { ShopContext } from '../../Context/ShopContext';
import { Item } from '../Item/Item';
import './Recommendations.css';

const Recommendations = () => {
  const { all_product, searchQuery, favouriteClothing, favouriteGender } = useContext(ShopContext);
  const [recommendedProductIds, setRecommendedProductIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecommendations = async () => {
      try {
        const response = await fetch('https://api.topcare.fashion/recommendations', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'auth-token': localStorage.getItem('auth-token'),
          },
          body: JSON.stringify({
            user_id: null, // This will be fetched in the backend
            favourite_clothing: [favouriteClothing],
            favourite_gender: [favouriteGender],
            search_keywords: [searchQuery],
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch recommendations');
        }

        const data = await response.json();
        console.log(data); // Check the response

        if (Array.isArray(data)) {
          // Flatten the recommended items from the array of recommendations
          const ids = data.flatMap(rec =>
            rec.recommended_items.map(itemId => ({ id: itemId, type: rec.type }))
          );

          // Update state with the collected ids
          setRecommendedProductIds(ids);

          // Store recommended items in local storage
          localStorage.setItem('recommendedItems', JSON.stringify(ids));
        } else {
          throw new Error('Expected an array but received something else');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRecommendations();
  }, [searchQuery, favouriteClothing, favouriteGender]);

  // Retrieve recommended items from local storage if needed
  useEffect(() => {
    const storedItems = localStorage.getItem('recommendedItems');
    if (storedItems) {
      setRecommendedProductIds(JSON.parse(storedItems));
    }
  }, []);

  if (loading) {
    return <></>;
  }

  if (error && localStorage.getItem('auth-token')) {
    console.error(error);
    return <div>Failed to load recommendations</div>;
  }

  // Find the recommended products from all_product based on the IDs and their types
  const recommendedProducts = all_product.filter(product =>
    recommendedProductIds.some(rec => rec.id === product.id)
  );

  if (recommendedProducts.length === 0) {
    return <></>;
  }

  console.log(recommendedProducts);

  return (
    <div className='popular'>
      {localStorage.getItem('auth-token') ? 
      <div className='popular-section'>
        <h1>Recommended For You</h1>
        <hr />
        <div className="popular-item">
          {recommendedProducts.slice(0, 4).map((item) => ( // Limit to 4 items
            <Item key={item.id} id={item.id} name={item.name} image={item.image} new_price={item.new_price} amountSold={item.amountSold} />
          ))}
        </div>
      </div>
      :
      <></>
      }
    </div>
  );
};

export default Recommendations;
