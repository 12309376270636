import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './CSS/Favourites.css';

const Favourites = () => {
  const [favourites, setFavourites] = useState([]);

  // Fetch favourite products from the backend
  const fetchFavourites = async () => {
    const response = await fetch('https://api.topcare.fashion/api/products/favourites', {
      headers: {
        'auth-token': localStorage.getItem('auth-token'),
      },
    });
    const data = await response.json();
    setFavourites(data.favourites);
  };

  useEffect(() => {
    fetchFavourites();
  }, []);

  return (
    <div className="favourites-container">
        <div className="favourites-header">
            <h1>Liked Products</h1>
        </div>

        <div className="favourites-list">
            {favourites.length > 0 ? (
            favourites.map((product) => (
                <Link to={`/product/${product.id}`} key={product.id} className="favourite-item-link">
                    <div className="favourite-item">
                        <img src={product.image} alt={product.name} className="favourite-item-img" />
                        <div className="favourite-item-info">
                            <p>{product.name}</p>
                            <p>${product.new_price}</p>
                        </div>
                    </div>
                </Link>
            ))
            ) : (
            <p>No favourites added yet.</p>
            )}
        </div>
    </div>
  );
};

export default Favourites;
