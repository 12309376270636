import React from 'react'
import './Footer.css'
import instagram_icon from '../Assets/instagram_icon.svg'
import facebook_icon from '../Assets/facebook_icon.svg'
import twitter_icon from '../Assets/twitter_icon.svg'
import telegram_icon from '../Assets/telegram_icon.svg'
import { Link } from 'react-router-dom';
import { NewsLetter } from '../NewsLetter/NewsLetter'

export const Footer = () => {
  return (
    <div className='footer'>
        <NewsLetter/>
        <div className='footer-copyright'>
              <div className="footer-social-icon">
                  <div className='footer-icons-container'>
                      <a href="https://www.instagram.com/tcf_2024term4/" target="_blank" rel="noopener noreferrer">
                          <img src={instagram_icon} alt="Instagram" />
                      </a>
                      <a href="https://www.facebook.com/profile.php?id=61566758854528" target="_blank" rel="noopener noreferrer">
                          <img src={facebook_icon} alt="Facebook" />
                      </a>
                      <a href="https://x.com/TCF_2024Term4" target="_blank" rel="noopener noreferrer">
                          <img src={twitter_icon} alt="Twitter" />
                      </a>
                      <a href="https://t.me/TCF_2024Term4" target="_blank" rel="noopener noreferrer">
                          <img src={telegram_icon} alt="Telegram" />
                      </a>
                  </div>
              </div>

              <div className="footer-content">
                <p>
                    <a href="https://promo.topcare.fashion/" target="_blank" rel="noopener noreferrer">
                        About Us
                    </a>
                </p>
                <p><Link to='/returnpolicy'>Return Policy</Link></p>
                <p><a href="mailto:topcarefashion2024term4@gmail.com">Email Support</a></p>
              </div>

              <hr />
            <p>&copy; 2024 Top Care Fashion. All rights reserved.</p>
        </div>
    </div>
  )
}
