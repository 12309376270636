import React, { useContext, useState, useEffect, useRef } from 'react';
import './Navbar.css';
import logo from '../Assets/logo.png';
import cart_icon from '../Assets/cart_icon.svg';
import { Link, useNavigate } from 'react-router-dom';
import { ShopContext } from '../../Context/ShopContext';
import profile_icon from '../Assets/nav-profile.svg';
import chat_icon from '../Assets/chat_icon.svg';
import image_search_icon from '../Assets/image_search_icon.svg';

export const Navbar = () => {
  const [menu, setMenu] = useState("shop");

  const { getTotalCartItems, setSearch, all_product } = useContext(ShopContext);

  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const navigate = useNavigate();

  const handleSearch = (e) => {
    if (e) {
      e.preventDefault();
    }
    
    if (searchQuery.trim()) {
      setSearch(searchQuery);
      setSuggestions([]);
      navigate(`/search?q=${searchQuery}`);
    }
  };
  
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.trim()) {
      const filteredSuggestions = all_product.filter(product => 
        product.name.toLowerCase().includes(query.toLowerCase())
      );
      setSuggestions(filteredSuggestions.slice(0, 10));
    } else {
      setSuggestions([]);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className='navbar'>
      <div className="nav-logo">
        <Link to='/'><img src={logo} alt="Logo" onClick={() => setMenu("shop")} className={menu === "shop" ? "active" : ""}/></Link>
        {localStorage.getItem('auth-token') ? <Link to='/sellerpanel'><button>Seller Center</button></Link> : <></>}
      </div>

      <div className='nav-Contents'>
        <div className="nav-search-bar">
          <form onSubmit={handleSearch}>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchInputChange}
              placeholder="Search products..."
            />
          </form>
          <Link to='/imagesearch'>
            <img src={image_search_icon} alt="" className='image-search-icon'/>
          </Link>

          {suggestions.length > 0 && (
            <ul className="suggestions-dropdown">
              {suggestions.map((suggestion, index) => (
                <li 
                  key={index} 
                  onClick={() => {
                    setSearchQuery(suggestion.name);
                    setSuggestions([]);
                    setTimeout(() => {
                      setSearch(suggestion.name);
                      navigate(`/search?q=${suggestion.name}`);
                    }, 0);
                  }}>
                  {suggestion.name}
                </li>
              ))}
            </ul>
          )}

        </div>

        <ul className="nav-menu">
          <li onClick={() => setMenu("shop")} className={menu === "shop" ? "active" : ""}>
            <Link to='/'>Home</Link>
          </li>
          <li onClick={() => setMenu("mixmatch")} className={menu === "mixmatch" ? "active" : ""}>
            <Link to='/mixmatch'>3D Mix & Match</Link>
          </li>
          <li onClick={() => setMenu("newarrivals")} className={menu === "newarrivals" ? "active" : ""}>
            <Link to='/newarrivals'>New Arrivals</Link>
          </li>
          <li onClick={() => setMenu("men")} className={menu === "men" ? "active" : ""}>
            <Link to='/men'>Men</Link>
          </li>
          <li onClick={() => setMenu("women")} className={menu === "women" ? "active" : ""}>
            <Link to='/women'>Women</Link>
          </li>
        </ul>
      </div>
      
      <div className="nav-login-cart">
        {localStorage.getItem('auth-token') ? <></>
        : <Link to='/login'><button>Login</button></Link>}

        {localStorage.getItem('auth-token') ? 
        <div>
          <Link to='/chatlist'><img src={chat_icon} alt="chat" /></Link>
          <Link to='/cart'><img src={cart_icon} alt="Cart" /></Link>
          <div className="nav-cart-count">{getTotalCartItems()}</div>
        </div> 
        : <></> }

        <div className="nav-profile" ref={dropdownRef}>
          {localStorage.getItem('auth-token') ? (
            <>
              <img src={profile_icon} alt="Profile" onClick={toggleDropdown} />
              {isDropdownVisible && (
                <div className="dropdown-menu">
                  <Link to='/profile' onClick={() => setDropdownVisible(false)}>Profile</Link>
                  <Link to='/favourites' onClick={() => setDropdownVisible(false)}>Liked Products</Link>
                  <Link to='/orders' onClick={() => setDropdownVisible(false)}>My Orders</Link>
                  <Link onClick={() => {
                    localStorage.removeItem('auth-token');
                    setDropdownVisible(false);
                    window.location.replace('/');
                  }}>Logout</Link>
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
