import React from 'react'
import Navbar from './Components/SellerNav/Navbar'
import Seller from './Pages/Seller/Seller'

const SellerPanel = () => {
  return (
    <div>
      {/* Seller NavBar */}
      <Navbar/>
      <Seller/>
    </div>
  )
}

export default SellerPanel