import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './CartItems.css';
import { ShopContext } from '../../Context/ShopContext';
import remove_icon from '../Assets/cart_cross_icon.svg';
import add_icon from '../Assets/cart_add_icon.svg';

export const CartItems = () => {
    const [shippingFee, setShippingFee] = useState(2);
    const [promoCode, setPromoCode] = useState('');
    const [promoCodeDiscount, setPromoCodeDiscount] = useState(0);  // Separate state for promo code discount
    const [pointsDiscount, setPointsDiscount] = useState(0);  // Separate state for points discount
    const [points, setPoints] = useState(0);
    const [redeemPoints, setRedeemPoints] = useState(false);  // State for points redemption toggle
    const [pointsDeducted, setPointsDeducted] = useState(0);
    const { getTotalCartAmount, all_product, cartItems, removeFromCart, addToCart, tier } = useContext(ShopContext);
    const navigate = useNavigate();

    // Calculate the final amount including both discounts
    const finalAmount = getTotalCartAmount() + shippingFee - promoCodeDiscount - pointsDiscount;
    let emptyCart = true;

    const handleCheckout = () => {
        const cartProducts = all_product.filter((e) => cartItems[e.id] > 0).map((e) => ({
            id: e.id,
            image: e.image,
            name: e.name,
            new_price: e.new_price,
            quantity: cartItems[e.id],
            sellerID: e.sellerID,
        }));

        navigate('/checkout', {
            state: {
                amount: finalAmount,
                cartProducts: cartProducts,
                pointsDeducted: pointsDeducted,
            },
        });
    };

    useEffect(() => {
        const fetchUserData = async () => {
            await fetch('https://api.topcare.fashion/api/user/profile', {
                headers: {
                    'auth-token': localStorage.getItem('auth-token'),
                },
            })
            .then((res) => res.json())
            .then((data) => {
                setPoints(data.points);
            });
        };

        fetchUserData();

        if (tier === 'Gold' || tier === 'Platinum') {
            setShippingFee(0);
        } else {
            setShippingFee(2);
        }
    }, [tier]);

    const handlePromoCodeChange = (e) => {
        setPromoCode(e.target.value);
    };

    const handleApplyPromoCode = async () => {
        try {
            const response = await fetch('https://api.topcare.fashion/api/promos/apply', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': localStorage.getItem('auth-token'),
                },
                body: JSON.stringify({ code: promoCode }),
            });

            const result = await response.json();

            if (response.ok) {
                setPromoCodeDiscount(result.discount);  // Set the promo code discount
            } else {
                alert(result.errors);
            }
        } catch (error) {
            console.error('Error applying promo code:', error);
            alert('Failed to apply promo code');
        }
    };

    // Handle redeeming points
    const handleRedeemPoints = () => {
        if (!redeemPoints) {
            let discountValue = 0;
            let deductedPoints = 0;  // Variable to store the deducted points
            const totalBeforeDiscounts = getTotalCartAmount() + shippingFee - promoCodeDiscount;

            // Calculate the discount based on tier
            switch (tier) {
                case 'Gold':
                    discountValue = Math.floor(points / 18);  // Every 18 points worth $1 off
                    deductedPoints = Math.min(points, discountValue * 18);  // Calculate points deducted
                    break;
                case 'Platinum':
                    discountValue = Math.floor(points / 15);  // Every 15 points worth $1 off
                    deductedPoints = Math.min(points, discountValue * 15);  // Calculate points deducted
                    break;
                default:
                    discountValue = Math.floor(points / 20);  // Every 20 points worth $1 off
                    deductedPoints = Math.min(points, discountValue * 20);  // Calculate points deducted
                    break;
            }

            // Cap the discount so it doesn't exceed the total bill amount
            if (discountValue > totalBeforeDiscounts) {
                discountValue = totalBeforeDiscounts;  // Cap the discount to the total amount
                deductedPoints = totalBeforeDiscounts * 20;  // Adjust the points deducted accordingly
            }

            if (discountValue > 0) {
                setPointsDiscount(discountValue);  // Apply the points discount
                setPointsDeducted(deductedPoints);  // Store the deducted points
                setRedeemPoints(true);  // Mark points as redeemed
            } else {
                alert("You don't have enough points to redeem.");
            }
        } else {
            // Undo the redemption
            setPointsDiscount(0);  // Reset the points discount
            setPointsDeducted(0);  // Reset the deducted points
            setRedeemPoints(false);  // Reset the redeem state
        }
    };


    return (
        <div className='cartitems'>
            <div className="cartitems-format-main">
                <p>Products</p>
                <p>Title</p>
                <p>Price</p>
                <p></p>
                <p>Total</p>
            </div>
            <hr />

            {all_product.map((e) => {
                if (cartItems[e.id] > 0) {
                    emptyCart = false;
                    return (
                        <div key={e.id}>
                            <div className="cartitems-format">
                                <img src={e.image} alt={e.name} className='carticon-product-icon' />
                                <p>{e.name}</p>
                                <p>${e.new_price}</p>
                                <div className="cartitems-quantity-container">
                                    <button className='cartitems-remove-icon' onClick={() => removeFromCart(e.id)}>
                                        <img src={remove_icon} alt="Remove" />
                                    </button>
                                    <div className='cartitems-quantity'>{cartItems[e.id]}</div>
                                    <button className='cartitems-add-icon' onClick={() => addToCart(e.id)}>
                                        <img src={add_icon} alt="Add" />
                                    </button>
                                </div>
                                <p>${(e.new_price * cartItems[e.id])}</p>
                            </div>
                            <hr />
                        </div>
                    );
                }
                return null;
            })}

            <div className="cartitems-down">
                <div className="cartitems-total">
                    <h1>Cart Total 
                        {tier === 'Gold' || tier === 'Platinum' ? (
                            <span className="free-shipping-badge"> (Gold/Platinum Member)</span>
                        ) : <></>}
                    </h1>
                    
                    <div>
                        {/* Subtotal */}
                        <div className='cartitems-total-item'>
                            <p>Subtotal</p>
                            <p>${getTotalCartAmount()}</p>
                        </div>
                        <hr />

                        {/* Promo Code Discount */}
                        {promoCodeDiscount > 0 && (
                            <>
                                <div className="cartitems-total-item">
                                    <p>Promo Code Discount</p>
                                    <p>-${promoCodeDiscount}</p>
                                </div>
                                <hr />
                            </>
                        )}

                        {/* Points Discount */}
                        {pointsDiscount > 0 && (
                            <>
                                <div className="cartitems-total-item">
                                    <p>Points Discount</p>
                                    <p>-${pointsDiscount}</p>
                                </div>
                                <hr />
                            </>
                        )}

                        {/* Shipping Fee */}
                        <div className="cartitems-total-item">
                            <p>Shipping Fee</p>
                            <p>
                                {tier === 'Gold' || tier === 'Platinum' ? (
                                    <span className="free-shipping-text">Free</span>
                                ) : `$${shippingFee}`}
                            </p>
                        </div>
                        <hr />

                        {/* Total with both discounts applied */}
                        <div className="cartitems-total-item">
                            <h3>Total</h3>
                            <h3>${finalAmount.toFixed(0)}</h3>
                        </div>
                    </div>
                    <button onClick={!emptyCart ? handleCheckout : null} disabled={emptyCart}>
                        Proceed to Checkout
                    </button>
                </div>

                {/* Promo Code Section */}
                <div className="cartitems-promocode">
                    <p>Enter Promo code:</p>
                    <div className="cartitems-promobox">
                        <input type="text" placeholder='Promo Code' value={promoCode} onChange={handlePromoCodeChange} />
                        <button onClick={handleApplyPromoCode}>Submit</button>
                    </div>

                    {/* Points Redemption Section */}
                    <div className="cartitems-points">
                        <p className='cartitems-points-p'>
                            Top Care Points: {points - pointsDeducted}
                            <button onClick={handleRedeemPoints} className='cartitems-points-button'>
                                {redeemPoints ? 'Undo' : 'Redeem'}
                            </button>
                        </p>
                    </div>
                </div>
    
            </div>
        </div>
    );
};
