import React, { useState } from 'react';
import './NewsLetter.css';

export const NewsLetter = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubscribe = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch('https://api.topcare.fashion/api/newsletter/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      
      if (response.ok) {
        setMessage(data.message);
      } else {
        setMessage(data.error);
      }
    } catch (error) {
      console.error('Error subscribing:', error);
      setMessage('Something went wrong. Please try again later.');
    }
  };

  return (
    <div className='newsletter'>
        <h1>Get Exclusive Offers on Email</h1>
        <p>Subscribe to our newsletter to stay updated</p>
        <form onSubmit={handleSubscribe}>
            <input 
              type='email' 
              placeholder='Your Email' 
              value={email} 
              onChange={(e) => setEmail(e.target.value)}
              required 
            />
            <button type='submit'>Subscribe</button>
        </form>
        {message && <p>{message}</p>}
    </div>
  );
};
